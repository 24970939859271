import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const quickQuoteRate = createSlice({
  name: 'getCarrierRates',
  initialState: {
    QuickQuoteRateValues: {}
  },
  reducers: {
    setQuickQuoteRateValues: (state, action) => {
      return action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setQuickQuoteRateValues } = quickQuoteRate.actions;

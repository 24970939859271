import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MonoStackedBar from 'mono-stacked-bar';
import 'mono-stacked-bar/dist/index.css';
import { styled } from '@mui/material/styles';
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';

function FalconDashboard() {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#66bb6a' : '#308fe8'
    }
  }));

  const colors = ['#393986', '#6767aa', '#56b5b5'];

  const [age, setAge] = useState('');

  const handleChange = event => {
    setAge(event.target.value);
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Card sx={{ minWidth: '30vw', height: '60vh' }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6'>Cost by Technology</Typography>
              <FormControl sx={{ minWidth: '5vw' }}>
                {/* <InputLabel id='demo-simple-select-label'>
                  Month View
                </InputLabel> */}
                <Select
                  placeholder='Month View'
                  defaultValue='Month View'
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={age}
                  // label='Age'
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value=''>
                    <em>Month View</em>
                  </MenuItem>
                  <MenuItem value={20}>Week View</MenuItem>
                  <MenuItem value={30}>Day View</MenuItem>
                  <MenuItem value={40}>Year View</MenuItem>
                </Select>
              </FormControl>
              <Button size='small'>Today</Button>
            </Box>
            <Box
              sx={{
                height: '40vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around'
              }}
            >
              <div>
                <Typography variant='caption'>Polymer</Typography>
                <BorderLinearProgress variant='determinate' value={50} />
              </div>
              <div>
                <Typography variant='caption'>PUR</Typography>
                <BorderLinearProgress variant='determinate' value={40} />
              </div>
              <div>
                <Typography variant='caption'>WB</Typography>
                <BorderLinearProgress variant='determinate' value={20} />
              </div>
              <div>
                <Typography variant='caption'>HM</Typography>
                <BorderLinearProgress variant='determinate' value={30} />
              </div>
              <div>
                <MonoStackedBar
                  data={[
                    { value: 35, color: '#66bb6a' },
                    { value: 15, color: '#e53935' },
                    { value: 20, color: '#2196f3' },
                    { value: 35, color: '#ffb74d' }
                  ]}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: '30vw', height: '60vh' }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6'>Cost by Technology</Typography>
              <FormControl sx={{ minWidth: '5vw' }}>
                {/* <InputLabel id='demo-simple-select-label'>
                  Month View
                </InputLabel> */}
                <Select
                  placeholder='Month View'
                  defaultValue='Month View'
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={age}
                  // label='Age'
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value=''>
                    <em>Month View</em>
                  </MenuItem>
                  <MenuItem value={20}>Week View</MenuItem>
                  <MenuItem value={30}>Day View</MenuItem>
                  <MenuItem value={40}>Year View</MenuItem>
                </Select>
              </FormControl>
              <Button size='small'>Today</Button>
            </Box>
            <Box
              sx={{
                height: '40vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around'
              }}
            >
              <div>
                <Typography variant='caption'>Polymer</Typography>
                <BorderLinearProgress variant='determinate' value={50} />
              </div>
              <div>
                <Typography variant='caption'>PUR</Typography>
                <BorderLinearProgress variant='determinate' value={40} />
              </div>
              <div>
                <Typography variant='caption'>WB</Typography>
                <BorderLinearProgress variant='determinate' value={20} />
              </div>
              <div>
                <Typography variant='caption'>HM</Typography>
                <BorderLinearProgress variant='determinate' value={30} />
              </div>
              <div>
                <MonoStackedBar
                  data={[
                    { value: 35, color: '#66bb6a' },
                    { value: 15, color: '#e53935' },
                    { value: 20, color: '#2196f3' },
                    { value: 35, color: '#ffb74d' }
                  ]}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default FalconDashboard;

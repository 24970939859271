import React, { useEffect, useRef } from 'react';
import {
  FormControl,
  TextField,
  Button,
  Skeleton,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
  Chip,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ExploreIcon from '@mui/icons-material/Explore';
import { Col, Row } from 'react-bootstrap';
import './GetRates.css';
import './KanbanCss.css';
import GuaranteeIcon from '../../assets/guaranteed.svg';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import FedExLogo from '../../assets/carrier logos/fedex.png';
import fedexExpress from '../../assets/carrier logos/fedexExpress.png';
import ABFLogo from '../../assets/carrier logos/ABFS.png';
import EXLALogo from '../../assets/carrier logos/EXLA.png';
import PITOHIO from '../../assets/carrier logos/PITD.png';
import CTLogo from '../../assets/carrier logos/central-transport.png';
import PYLELogo from '../../assets/carrier logos/PYLE.png';
import YRCLogo from '../../assets/carrier logos/RDWY.png';
import RLLogo from '../../assets/carrier logos/RLcarriers.png';
import WardLogo from '../../assets/carrier logos/WARD.png';
import AACTLogo from '../../assets/carrier logos/AACT.png';
import UPSLogo from '../../assets/carrier logos/Tfroce freight.png';
import UPSOldLogo from '../../assets/carrier logos/ups.png';
import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import SEFLlogo from '../../assets/carrier logos/Southeastern freight lines.png';
import NEWPENLogo from '../../assets/carrier logos/New Penn.png';
import SAIALogo from '../../assets/carrier logos/SAIA.png';
import CNWYLogo from '../../assets/carrier logos/CNWY.png';
import ReddawayLogo from '../../assets/carrier logos/Reddaway.png';
import { Box } from '@mui/system';
import { Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import USALocations from '../../apis/USALocations.json';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useStyles } from '../../pages/GetQoute/styles';

function QuickQuoteTabPanel({
  OriginLocation,
  DestinationLocation,
  OrderWeight,
  selectedClass,
  errorMessageApi,
  quote,
  fetchData,
  obj,
  SelectedRowData,
  successRates,
  EstesApiLoading,
  checkedProp,
  shipTypeValue,
  noAssignedCarriers
}) {
  const [open, setOpen] = useState(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  // const [EstesApiLoading, setEstesApiLoading] = useState(false);
  const [defaultOriginLoc, setDefaultOriginLoc] = useState(OriginLocation);
  const [defaultDestinationLoc, setDefaultDestinationLoc] =
    useState(DestinationLocation);

  const [shipFromOpen, setShipFromOpen] = useState(false);
  const [shipToOpen, setShipToOpen] = useState(false);
  const [shipCity, setShipCity] = useState([]);
  const [ErrMsgArray, setErrMsgArray] = useState();
  const [shipFromHoveredValue, setShipFromHoveredValue] = useState(null);
  const [shipToHoveredValue, setShipToHoveredValue] = useState(null);
  const [freightClassHoveredValue, setFreightClassHoveredValue] =
    useState(null);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  let TempOrigin = OriginLocation.split(' ');
  let FinalOrigin = {};

  const City = TempOrigin[1];
  const State = TempOrigin[2];
  const ZipCode = TempOrigin[0];
  FinalOrigin = {
    City,
    State,
    ZipCode
  };

  let TempDestination = DestinationLocation.split(' ');
  let FinalDestination = {};

  const City2 = TempDestination[1];
  const State2 = TempDestination[2];
  const ZipCode2 = TempDestination[0];
  FinalDestination = {
    City2,
    State2,
    ZipCode2
  };
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(FinalOrigin);
  const ShipFromloading = shipFromOpen && options.length === 0;
  const ShipToLoading = shipToOpen && options.length === 0;
  const [validationErrors, setValidationErrors] = useState({});
  const [shipFromErr, setShipFromErr] = useState(false);
  const [shipToFinalAddress, setShipToFinalAddress] =
    useState(FinalDestination);
  const [requoteWeight, setRequoteWeight] = useState(OrderWeight);
  const [requoteClass, setRequoteClass] = useState(selectedClass);

  const handleUSAddresses = () => {
    let tempCity = [];
    for (let index = 0; index < USALocations.length; index++) {
      const City = USALocations[index].city;
      const State = USALocations[index].state;
      const ZipCode = USALocations[index].zip_code;

      tempCity.push(City + ' ' + State + ' ' + ZipCode);
      // tempState.push(State);
      // tempZipCode.push(ZipCode);
    }
    setShipCity(tempCity);
    // setShipState(tempState);
    // setShipZip(tempZipCode);
  };
  useEffect(() => {
    handleUSAddresses();
  }, []);

  const LoadingArray = [
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 }
  ];
  const ErrorMessageApiHanlder = () => {
    var ErrMsgArray = [
      { title: 'CTQuoteRate', msg: '', logo: CTLogo },
      {
        title: 'FedEx',
        msg: '',
        logo: shipTypeValue === 'Parcel' ? fedexExpress : FedExLogo
      },
      { title: 'XPO', msg: '', logo: CNWYLogo },
      { title: 'Estes', msg: '', logo: EXLALogo },
      { title: 'SAIA', msg: '', logo: SAIALogo },
      { title: 'ABF', msg: '', logo: ABFLogo },
      { title: 'PittOhio', msg: '', logo: PITOHIO },
      { title: 'Sefl', msg: '', logo: SEFLlogo },
      { title: 'NewPenn', msg: '', logo: NEWPENLogo },
      { title: 'ODFL', msg: '', logo: ODFLlogo },
      {
        title: 'UPS',
        msg: '',
        logo: shipTypeValue === 'Parcel' ? UPSOldLogo : UPSLogo
      },
      {
        title: 'YRC',
        msg: '',
        logo: YRCLogo
      },
      { title: 'Reddaway', msg: '', logo: ReddawayLogo },
      { title: 'Ward', msg: '', logo: WardLogo },
      { title: 'RL', msg: '', logo: RLLogo },
      { title: 'AduiePyle', msg: '', logo: PYLELogo },
      { title: 'AAACooper', msg: '', logo: AACTLogo }
    ];
    const FinalErrArray = [];
    for (let i = 0; i < errorMessageApi.length; i++) {
      const errorApi = errorMessageApi[i];
      const ErrName = errorApi?.URL?.split('/');
      const LastName = ErrName[ErrName?.length - 1];
      const errorMsgPair = {
        message: errorApi?.message
      };
      FinalErrArray.push({
        // title: LastName,
        msg:
          errorApi.message.length > 2
            ? errorApi.message
            : JSON.stringify(errorMsgPair, null, 2),
        logo: ErrMsgArray.find(items =>
          LastName.includes(items.title) ? items.logo : null
        )
      });
    }
    setErrMsgArray(FinalErrArray);
  };

  useEffect(() => {
    ErrorMessageApiHanlder();
  }, [errorMessageApi]);

  useEffect(() => () => setState({ open: false }), []);

  const RateErrorTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ce7a7a',
      color: 'white',
      maxWidth: 800,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    },
    [`& .${tooltipClasses.arrow}`]: {
      // backgroundColor: '#f5f5f9',
      color: '#ce7a7a',
      maxWidth: 1100,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    }
  }));

  const onShipFromChange = (event, values) => {
    let FinalObj = {};
    var filtered = [];
    if (values !== null) {
      filtered = shipCity.filter(option =>
        option?.toLowerCase().includes(values?.toLowerCase())
      );
    }
    setShipFromHoveredValue(filtered[0]);
    if (values !== null) {
      setDefaultOriginLoc(values);
      const tempData = values.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipFromErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else if (tempData.length === 5) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      }

      setShipFromFinalAddress(FinalObj);
    } else {
      // setShipFromFinalAddress('');
    }
  };

  const onShipToChange = (event, values) => {
    let FinalObj2 = {};
    var filtered = [];
    if (values !== null) {
      filtered = shipCity.filter(option =>
        option?.toLowerCase().includes(values?.toLowerCase())
      );
    }
    setShipToHoveredValue(filtered[0]);
    if (values !== null) {
      setDefaultDestinationLoc(values);
      setShipFromErr(false);
      const tempData = values.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipToErr: null };
        setValidationErrors(updatedErrors);
        const City2 = tempData[0];
        const State2 = tempData[1];
        const ZipCode2 = tempData[2];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
        setShipToFinalAddress(FinalObj2);
      } else if (tempData.length === 5) {
        const City2 = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State2 = tempData[3];
        const ZipCode2 = tempData[4];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
        setShipToFinalAddress(FinalObj2);
      } else {
        const City2 = tempData[0] + ' ' + tempData[1];
        const State2 = tempData[2];
        const ZipCode2 = tempData[3];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
        setShipToFinalAddress(FinalObj2);
      }
      // setShipToFinalAddress(FinalObj2);
    } else {
      setShipToFinalAddress('');
      setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 300
  });

  const handleRequote = () => {
    const reg = /^-?\d+\.?\d*$/;
    let RequoteObj = JSON.parse(JSON.stringify(obj));
    let DeepCloneRequoteObj = JSON.parse(JSON.stringify(obj));
    // let newObj = { ...obj, };
    if (
      // regex.test(shipFromFinalAddress.City) &&
      shipFromFinalAddress.City.length >= 3
    ) {
      RequoteObj.shipFrom.city = shipFromFinalAddress.City;
      DeepCloneRequoteObj.shipFrom.city = shipFromFinalAddress.City;
    }
    if (shipFromFinalAddress.State.length === 2) {
      RequoteObj.shipFrom.stateOrProvinceCode = shipFromFinalAddress.State;
      DeepCloneRequoteObj.shipFrom.stateOrProvinceCode =
        shipFromFinalAddress.State;
    }
    if (
      // reg.test(shipFromFinalAddress.ZipCode) &&
      shipFromFinalAddress.ZipCode.length >= 3
    ) {
      RequoteObj.shipFrom.postalCode = shipFromFinalAddress.ZipCode;
      DeepCloneRequoteObj.shipFrom.postalCode = shipFromFinalAddress.ZipCode;
    }
    if (
      // regex.test(shipToFinalAddress.City2) &&
      shipToFinalAddress.City2.length >= 3
    ) {
      RequoteObj.shipTo.city = shipToFinalAddress.City2;
      DeepCloneRequoteObj.shipTo.city = shipToFinalAddress.City2;
    }
    if (shipToFinalAddress.State2.length === 2) {
      RequoteObj.shipTo.stateOrProvinceCode = shipToFinalAddress.State2;
      DeepCloneRequoteObj.shipTo.stateOrProvinceCode =
        shipToFinalAddress.State2;
    }
    if (
      reg.test(shipToFinalAddress.ZipCode2) &&
      shipToFinalAddress.ZipCode2.length >= 3
    ) {
      RequoteObj.shipTo.postalCode = shipToFinalAddress.ZipCode2;
      DeepCloneRequoteObj.shipTo.postalCode = shipToFinalAddress.ZipCode2;
    }
    RequoteObj.shipTo.streetLines =
      shipToFinalAddress.City2 +
      ' ' +
      shipToFinalAddress.State2 +
      ' ' +
      shipToFinalAddress.ZipCode2;
    RequoteObj.shipFrom.streetLines =
      shipFromFinalAddress.City +
      ' ' +
      shipFromFinalAddress.State +
      ' ' +
      shipFromFinalAddress.ZipCode;
    RequoteObj.commodityInfo[0].weight.value = requoteWeight;
    RequoteObj.commodityInfo[0].freightClass = requoteClass;
    DeepCloneRequoteObj.commodityInfo[0].weight.value = requoteWeight;
    DeepCloneRequoteObj.commodityInfo[0].freightClass = requoteClass;

    DeepCloneRequoteObj.shipFrom.countryCode = 'US';
    DeepCloneRequoteObj.shipTo.countryCode = 'US';

    fetchData(RequoteObj, DeepCloneRequoteObj);

    // window.location.reload();
  };

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checkedProp);
  }, [checkedProp]);

  const handleShowHideErrosChnage = event => {
    setChecked(event.target.checked);
  };

  // state for storing the isotope object, with an initial value of null
  // state for storing the filter keyword, with an initial value of *, which matches everything
  const [filterKey, setFilterKey] = useState('*');
  const [sortKey, setSortKey] = useState('price');
  const [sortOrder, setSortOrder] = useState('ASC');

  const [detailDrawer, setDetailDrawer] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [filterValue, setFilterValue] = useState(0);
  const [sortValue, setSortValue] = useState(0);

  const [currentRate, setCurrentRate] = useState({});

  const handleFilterValueChange = (event, newValue) => {
    setFilterValue(newValue);
  };
  const handleSortValueChange = (event, newValue) => {
    setSortValue(newValue);
  };

  const handleSortToggle = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };

  const handleViewRate = rate => {
    setCurrentRate(rate);
    setDetailDrawer(true);
  };

  const handleViewRate1 = rate => {
    setCurrentRate(rate);
    setDetailModal(true);
  };

  const applyFilter = item => {
    switch (filterKey) {
      case '*':
        return true;
      case 'guaranteed':
        return item.isGuaranteedDelviery;
      case 'standard':
        return item.filterMode === 'standard';
      default:
        return false;
    }
  };

  const applySort = (rowA, rowB) => {
    switch (sortKey) {
      case 'price':
        return sortOrder === 'ASC'
          ? rowA.totalNetCharge.amount - rowB.totalNetCharge.amount
          : rowB.totalNetCharge.amount - rowA.totalNetCharge.amount;
      case 'days':
        const daysA = isNaN(parseInt(rowA.transitTime))
          ? 9999
          : parseInt(rowA.transitTime);
        const daysB = isNaN(parseInt(rowB.transitTime))
          ? 9999
          : parseInt(rowB.transitTime);
        return sortOrder === 'ASC' ? daysA - daysB : daysB - daysA;
      case 'name':
        return sortOrder === 'ASC'
          ? rowA.carrierInfo.name.localeCompare(rowB.carrierInfo.name)
          : rowB.carrierInfo.name.localeCompare(rowA.carrierInfo.name);
      default:
        return 0; // Default case: no sorting
    }
  };

  const applySubChargesSort = (rowA, rowB) => {
    return rowA.order - rowB.order;
  };

  const formatePrice = price => {
    return price?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white',
    maxHeight: '90vh',
    overflowY: 'scroll'
  };
  const maxLimit = 14999;
  const maxLimitParcel = 150;

  const handleReQuoteWeightChange = e => {
    const inputValue = e.replace(/[^0-9]/g, '');
    if (shipTypeValue === 'Parcel') {
      if (parseInt(inputValue, 10) > maxLimitParcel) {
        // setRequoteWeight(String(maxLimit));
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: 'Maximum weight limit is 150 lbs'
        };
        setValidationErrors(updatedErrors);
      } else {
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: null
        };
        setValidationErrors(updatedErrors);
        setRequoteWeight(inputValue);
      }
    } else if (shipTypeValue === 'LTL' && parseInt(inputValue, 10) > maxLimit) {
      // setRequoteWeight(String(maxLimit));
      const updatedErrors = {
        ...validationErrors,
        newAddedDimensionsRow: 'Maximum weight limit is 14999'
      };
      setValidationErrors(updatedErrors);
    } else {
      const updatedErrors = {
        ...validationErrors,
        newAddedDimensionsRow: null
      };
      setValidationErrors(updatedErrors);
      setRequoteWeight(inputValue);
    }
  };
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];
  const requoteClassChange = (event, newValue) => {
    var filteredClass = [];
    if (event !== null) {
      setRequoteClass(newValue);
      filteredClass = ClassArray.filter(option =>
        option.toLowerCase().includes(newValue?.toLowerCase())
      );
    }
    setFreightClassHoveredValue(filteredClass[0]);
  };
  const classes = useStyles();

  return (
    <div>
      <React.Fragment>
        <Modal
          open={detailModal}
          onClose={() => setDetailModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            className=' py-3 d-flex flex-column detail-bottom-drawer'
            style={style}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                onClick={() => setDetailModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div
              className=' py-3 mt-4 d-flex flex-column '
              style={{ width: '97%' }}
            >
              <Row
                className='px-3 d-flex flex-row'
                style={{ marginTop: '-30', marginBottom: -10 }}
              >
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className=''>
                    <img
                      alt={'st'}
                      src={`https://tms.dynamicwerx.com/carrier_logos/${currentRate?.carrierInfo?.logo}`}
                      width={90}
                    />
                  </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='d-block'>
                    <Typography
                      variant='h4'
                      className='fw-bold'
                      style={{ textAlign: 'center' }}
                    >
                      <span className='dayss'>
                        {isNaN(parseInt(currentRate.transitTime))
                          ? 'N/A'
                          : currentRate.transitTime === '0'
                          ? 'N/A'
                          : parseInt(currentRate.transitTime)}
                      </span>
                    </Typography>
                    <Typography
                      variant='body1'
                      className='text-black'
                      style={{ fontSize: '14px', textAlign: 'center' }}
                    >
                      Service Day(s)
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row className='px-3 d-flex flex-row' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Origin Terminal Info
                    </Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Address
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .streetLines
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .streetLines
                        : ' '
                    }`}</Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.originDetail?.contactAndAddress?.address.city
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .city + ','
                        : ' '
                    } ${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .stateOrProvinceCode
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .stateOrProvinceCode + ','
                        : ''
                    } ${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .postalCode
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .postalCode
                        : ''
                    }`}</Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Phone
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      <PhoneAndroidOutlinedIcon
                        style={{
                          height: 18,
                          width: 18
                        }}
                      />
                      {currentRate?.originDetail?.contactAndAddress?.contact
                        .phoneNumber ? (
                        <a
                          className='call-phone-number'
                          href={`tel:${currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber}`}
                        >
                          {currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber.replace(
                            /\D/g,
                            ''
                          ).length < 11
                            ? currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber
                                .replace(/\D/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                            : currentRate?.originDetail?.contactAndAddress
                                ?.contact.phoneNumber}
                        </a>
                      ) : (
                        ' '
                      )}
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Destination Terminal Info
                    </Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Address
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .streetLines
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.streetLines
                        : ' '
                    }`}</Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .city
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.city + ','
                        : ' '
                    } ${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .stateOrProvinceCode
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.stateOrProvinceCode + ','
                        : ''
                    } ${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .postalCode
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.postalCode
                        : ''
                    }`}</Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Phone
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      <PhoneAndroidOutlinedIcon
                        style={{
                          height: 18,
                          width: 18
                        }}
                      />
                      {currentRate?.destinationDetail?.contactAndAddress
                        ?.contact.phoneNumber ? (
                        <a
                          className='call-phone-number'
                          href={`tel:${currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber}`}
                        >
                          {currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber.replace(
                            /\D/g,
                            ''
                          ).length < 11
                            ? currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber
                                .replace(/\D/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                            : currentRate?.destinationDetail?.contactAndAddress
                                ?.contact.phoneNumber}
                        </a>
                      ) : (
                        ' '
                      )}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Box>
        </Modal>

        <Modal
          open={detailDrawer}
          onClose={() => setDetailDrawer(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            className=' py-3 d-flex flex-column detail-bottom-drawer'
            style={style}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                onClick={() => setDetailDrawer(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div
              className=' py-3 mt-4 d-flex flex-column '
              style={{ width: '97%' }}
            >
              <Row
                className='px-3 d-flex flex-row'
                style={{ marginTop: '-30', marginBottom: -10 }}
              >
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className=''>
                    <img
                      alt={'st'}
                      src={`https://tms.dynamicwerx.com/carrier_logos/${currentRate?.carrierInfo?.logo}`}
                      width={90}
                    />
                  </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='d-block'>
                    <Typography
                      variant='h4'
                      className='fw-bold'
                      style={{ textAlign: 'center' }}
                    >
                      <span className='dayss'>
                        {isNaN(parseInt(currentRate.transitTime))
                          ? 'N/A'
                          : currentRate.transitTime === '0'
                          ? 'N/A'
                          : parseInt(currentRate.transitTime)}
                      </span>
                    </Typography>
                    <Typography
                      variant='body1'
                      className='text-black'
                      style={{ fontSize: '14px', textAlign: 'center' }}
                    >
                      Service Day(s)
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row className='px-3 d-flex flex-row' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=' '
                      style={{ color: '#1565c0' }}
                    >
                      Origin
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {OriginLocation}
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=' '
                      style={{ color: '#1565c0' }}
                    >
                      Destination
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {DestinationLocation}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row className='px-3 d-flex flex-row' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Weight
                    </Typography>
                    <Typography
                      variant='h5'
                      className='info-label-value'
                    >{`${OrderWeight} lbs`}</Typography>
                  </div>
                </Col>
                {shipTypeValue !== 'Parcel' && (
                  <Col>
                    <div className='my-2'>
                      <Typography
                        variant='h6'
                        className=' '
                        style={{ color: '#1565c0' }}
                      >
                        Class
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>
                        {requoteClass}
                      </Typography>
                    </div>
                  </Col>
                )}
              </Row>
              {shipTypeValue !== 'Parcel' && (
                <Row className='px-3 d-flex flex-row ' style={{}}>
                  <Col>
                    <div className='my-2'>
                      <Typography
                        variant='h6'
                        className=''
                        style={{ color: '#1565c0' }}
                      >
                        Quote ID
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>
                        {currentRate?.quotationNumber}
                      </Typography>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className='px-3 d-flex flex-row ' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Charges Detail
                    </Typography>
                    {currentRate?.surcharges
                      ?.sort(applySubChargesSort)
                      .map(surcharge => (
                        <Typography
                          variant='h5'
                          className='info-label-value'
                        >{`${surcharge.description}`}</Typography>
                      ))}

                    <Typography
                      variant='body1'
                      className=' fw-bold my-1'
                      style={{ textDecoration: 'underline' }}
                    >
                      Total Price
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2 align-right'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Charges
                    </Typography>
                    {currentRate?.surcharges
                      ?.sort(applySubChargesSort)
                      .map(surcharge => (
                        <Typography variant='h5' className='info-label-value'>
                          {surcharge?.description?.includes('%')
                            ? `${surcharge.amount?.amount.toFixed(2)}%`
                            : formatePrice(surcharge.amount?.amount)}
                        </Typography>
                      ))}
                    <Typography
                      variant='body1'
                      className=' fw-bold my-1'
                      style={{ textDecoration: 'underline' }}
                    >
                      {formatePrice(currentRate?.totalNetCharge?.amount)}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
      <div>
        <Row>
          <Col lg={5}>
            <div style={{ display: 'flex' }}>
              <div>
                <FormControl
                  sx={{ mt: 1, minWidth: 170, zIndex: 1 }}
                  size='large'
                >
                  <Autocomplete
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option}
                      </Box>
                    )}
                    onBlur={() => {
                      if (shipFromHoveredValue) {
                        onShipFromChange(null, shipFromHoveredValue);
                      }
                    }}
                    disabled={obj.paymentType === '0' ? true : false}
                    filterOptions={filterOptions}
                    size='small'
                    id='asynchronous-demo'
                    sx={{ width: 250 }}
                    options={shipCity}
                    value={defaultOriginLoc}
                    onChange={(event, newValue) =>
                      onShipFromChange(event, newValue)
                    }
                    onInputChange={(event, newValue) =>
                      onShipFromChange(event, newValue)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        onKeyDown={e => {
                          if (e.key === 'Tab' && shipFromHoveredValue) {
                            onShipFromChange(e, shipFromHoveredValue);
                          }
                        }}
                        error={Boolean(validationErrors['shipFromErr'])}
                        helperText={validationErrors['shipFromErr']}
                        label='Ship From'
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        InputProps={{
                          style: {
                            height: 32,
                            fontSize: 12
                          },
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {ShipFromloading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl
                  sx={{ mt: 1, minWidth: 200, zIndex: 1 }}
                  size='large'
                >
                  <Autocomplete
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option}
                      </Box>
                    )}
                    // disabled={quote === 'quick-quote' ? false : true}
                    disabled={obj.paymentType === '1' ? true : false}
                    filterOptions={filterOptions}
                    value={defaultDestinationLoc}
                    disableListWrap
                    size='small'
                    id='asynchronous-demo'
                    sx={{ width: 250, ml: 3 }}
                    options={shipCity}
                    onChange={(event, newValue) =>
                      onShipToChange(event, newValue)
                    }
                    onInputChange={(event, newValue) =>
                      onShipToChange(event, newValue)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        onKeyDown={e => {
                          if (e.key === 'Tab' && shipToHoveredValue) {
                            onShipToChange(e, shipToHoveredValue);
                          }
                        }}
                        error={Boolean(validationErrors['shipToErr'])}
                        helperText={validationErrors['shipToErr']}
                        label='Ship To'
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        InputProps={{
                          style: {
                            height: 32,
                            fontSize: 12
                          },
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {ShipToLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          </Col>

          <Col lg={2}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <FormControl
                  sx={{ mt: 1, minWidth: 100, zIndex: 1 }}
                  size='large'
                >
                  <TextField
                    fullWidth
                    className={classes.inputRoot}
                    error={Boolean(validationErrors['newAddedDimensionsRow'])}
                    helperText={validationErrors['newAddedDimensionsRow']}
                    FormHelperTextProps={{
                      style: {
                        whiteSpace: 'nowrap',
                        margin: '0 0 0 0'
                      }
                    }}
                    disabled={quote === 'quick-quote' ? false : true}
                    value={requoteWeight}
                    onChange={e => handleReQuoteWeightChange(e.target.value)}
                    size='small'
                    sx={
                      shipTypeValue === 'Parcel'
                        ? { width: 150, marginRight: 1, fontSize: 12 }
                        : {
                            width: 90,
                            marginRight: 1,
                            fontSize: 12
                          }
                    }
                    id='outlined-password-input'
                    label='Weight'
                    type='number'
                    autoComplete='current-password'
                    InputLabelProps={{
                      style: { fontSize: 12 }
                    }}
                    inputProps={{
                      style: {
                        height: 15,
                        fontSize: 12
                      }
                    }}
                  />
                </FormControl>
              </div>
              {shipTypeValue !== 'Parcel' && (
                <FormControl
                  sx={{ mt: 1, minWidth: 100, zIndex: 1 }}
                  size='large'
                >
                  <Autocomplete
                    fullWidth
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option}
                      </Box>
                    )}
                    onBlur={() => {
                      if (freightClassHoveredValue) {
                        requoteClassChange(null, freightClassHoveredValue);
                      }
                    }}
                    name='Class'
                    size='small'
                    options={ClassArray}
                    // defaultValue={'50'}
                    value={requoteClass}
                    onChange={(event, newValue) =>
                      requoteClassChange(event, newValue)
                    }
                    onInputChange={(event, newValue) =>
                      requoteClassChange(event, newValue)
                    }
                    // sx={{ marginLeft: 1 }}
                    renderInput={params => (
                      <TextField
                        // required={true}
                        {...params}
                        onKeyDown={e => {
                          if (e.key === 'Tab' && freightClassHoveredValue) {
                            // e.preventDefault(); // prevent the default Tab behavior
                            requoteClassChange(e, freightClassHoveredValue);
                          }
                        }}
                        error={Boolean(validationErrors['shipToErr'])}
                        helperText={validationErrors['shipToErr']}
                        label='Class'
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        InputProps={{
                          style: {
                            height: 32,
                            fontSize: 12
                          },
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {ShipToLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
            </div>
          </Col>
          <Col lg={2}>
            <div style={{ display: 'flex' }}>
              <div>
                <FormControl
                  sx={{ mt: 1, minWidth: 120, zIndex: 1 }}
                  size='large'
                >
                  <TextField
                    defaultValue={materialDateInput}
                    // value={new Date()}
                    size='small'
                    label='Pickup Date'
                    sx={{
                      width: 170,
                      marginRight: 1,
                      fontSize: 12
                    }}
                    id='outlined-password-input'
                    type='date'
                    autoComplete='current-password'
                    InputLabelProps={{
                      style: { fontSize: 12 }
                    }}
                    inputProps={{
                      style: {
                        height: 15,
                        fontSize: 12
                      }
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div style={{ display: 'flex' }}>
              <div>
                <FormControl sx={{ mt: 1, minWidth: 30 }} size='large'>
                  <Button
                    disabled={
                      quote === 'quick-quote' &&
                      shipFromFinalAddress &&
                      shipToFinalAddress &&
                      requoteWeight
                        ? false
                        : true
                    }
                    sx={{
                      width: 90,
                      // marginRight: 1,
                      fontSize: 12
                    }}
                    variant='contained'
                    color='primary'
                    onClick={handleRequote}
                  >
                    ReQuote
                  </Button>
                </FormControl>
              </div>
              <div className='me-5'>
                <Chip
                  sx={{ mt: 1, ml: 1 }}
                  // variant='outlined'
                  label={
                    <FormControlLabel
                      onChange={e => console.log(e, 'swithchange')}
                      control={
                        <Switch
                          checked={checked}
                          onChange={handleShowHideErrosChnage}
                          // color='error'
                          size='small'
                        />
                      }
                      label={
                        <Typography variant='body2' sx={{ fontSize: '8px' }}>
                          {checked
                            ? 'Hide invalid carriers'
                            : 'Show invalid carriers'}
                        </Typography>
                      }
                    />
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
      </div>

      <div className='w-100'>
        <div
          className='d-flex flex-row mx-2'
          style={{ justifyContent: 'space-between' }}
        >
          <Box className='d-flex mb-2 ' sx={{ alignItems: 'center' }}>
            <Tabs
              onChange={handleFilterValueChange}
              value={filterValue}
              aria-label='Tabs where selection follows focus'
              selectionFollowsFocus
            >
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                onClick={() => setFilterKey('*')}
                label='All'
              />
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                onClick={() => setFilterKey('standard')}
                label='Standard'
              />
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                onClick={() => setFilterKey('guaranteed')}
                label='Guaranteed'
              />
              {/* <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('.g10')} label="10:00 - 10:30 AM" />
              <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('.g12')} label="12:00 PM" />
              <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('.g5')} label="05:00 PM" /> */}
            </Tabs>
          </Box>
          <Box className='d-flex' sx={{ alignItems: 'center' }}>
            <Typography
              variant='h6'
              className='fw-bold text-black me-2'
              style={{ cursor: 'pointer', fontSize: '13px' }}
              onClick={handleSortToggle}
            >
              {sortOrder === 'ASC' ? (
                <SortIcon
                  style={{ transform: 'rotateX(180deg)', fontSize: '16px' }}
                />
              ) : (
                <SortIcon style={{ fontSize: '16px' }} />
              )}{' '}
              Sort
            </Typography>
            <Tabs
              onChange={handleSortValueChange}
              value={sortValue}
              aria-label='Tabs where selection follows focus'
              selectionFollowsFocus
            >
              {/* icon={sortOrder === 'ASC' ? <SortIcon style={{ transform: 'rotateX(180deg)' }} /> : <SortIcon />} */}
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                iconPosition='start'
                label='Price'
                onClick={() => setSortKey('price')}
              />
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                iconPosition='start'
                label='Days'
                onClick={() => setSortKey('days')}
              />
              <Tab
                sx={{
                  fontSize: '13px',
                  minWidth: 'auto',
                  textTransform: 'none'
                }}
                className='px-2 fw-bold'
                iconPosition='start'
                label={sortOrder === 'ASC' ? 'Carrier A-Z' : 'Carrier Z-A'}
                onClick={() => setSortKey('name')}
              />
            </Tabs>
          </Box>
        </div>

        <div className='rates-data-container_quick_quote'>
          {EstesApiLoading ? (
            LoadingArray.map(items => (
              <div className='Error_Carrier_div w-100'>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={
                      EstesApiLoading
                        ? {
                            width: 60,
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                        : {
                            width: 'auto',
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                    }
                  ></div>
                  <Col lg={3}>
                    <Box
                      sx={{
                        height: 60,
                        margin: '20 0 0 17'
                      }}
                    >
                      <Skeleton variant='circular' width={40} height={40} />
                    </Box>
                  </Col>
                  <Col lg={2}>
                    <div style={{ cursor: 'pointer' }}>
                      <h2>
                        <Box sx={{ width: 120 }}>
                          <Skeleton />
                        </Box>
                      </h2>

                      <span
                        style={{
                          width: 'auto',
                          fontSize: 10,
                          fontWeight: 700,
                          position: 'absolute',
                          margin: '-14 0 0 0',
                          cursor: 'auto'
                        }}
                      >
                        <Box sx={{ width: 100, marginTop: 0.5 }}>
                          <Skeleton />
                        </Box>
                      </span>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 15
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700
                          }}
                        >
                          <Box sx={{ width: 110 }}>
                            <Skeleton />
                          </Box>
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal'
                            }}
                          >
                            <Box sx={{ width: 80, marginTop: -2 }}>
                              <Skeleton />
                            </Box>
                          </span>
                        </span>
                      </div>{' '}
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700,
                            margin: '0 0 0 18'
                          }}
                        >
                          <Box sx={{ width: 110, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal',
                              margin: '0 0 0 18'
                            }}
                          >
                            <Box
                              sx={{ width: 80, marginTop: -2, marginLeft: 3 }}
                            >
                              <Skeleton />
                            </Box>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          margin: '0 0 0 18'
                        }}
                      >
                        <Box sx={{ width: 110, marginLeft: 3 }}>
                          <Skeleton />
                        </Box>
                        <br />
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 'normal',
                            margin: '0 0 0 18'
                          }}
                        >
                          <Box sx={{ width: 80, marginTop: -2, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                        </span>
                      </span>
                    </div>{' '}
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <>
              {successRates
                .filter(applyFilter)
                .sort(applySort)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`filter-item ${item.filterMode} succ p-2`}
                    style={{ width: '100%' }}
                  >
                    <Row key={item.uniqueId} className='table-card'>
                      <span className='namee d-none'>
                        {item.carrierInfo.name}
                      </span>
                      <Col
                        className='d-flex flex-row h-100'
                        style={{ alignItems: 'center' }}
                      >
                        <img
                          alt=''
                          className='table-card-logo'
                          src={`https://tms.dynamicwerx.com/carrier_logos/${item.carrierInfo.logo}`}
                          width={100}
                        />
                      </Col>

                      <Col>
                        <Typography variant='h4' className='fw-bold' style={{}}>
                          {formatePrice(item.totalNetCharge.amount)}
                        </Typography>
                        {/* {item.isGuaranteedDelviery && ( */}
                        <Typography
                          variant='body2'
                          className='text-black'
                          style={{ fontSize: '13px' }}
                        >
                          {/* {item.udeliveryTime === 'INVALID DATE'
                            ? ''
                            : item.udeliveryTime}{' '} */}
                          {item.serviceType}
                          <br />
                          {item.deliveryTime}
                        </Typography>
                        {/* )} */}
                      </Col>

                      <Col>
                        <Typography
                          variant='h4'
                          className='fw-bold'
                          style={{ textAlign: 'center' }}
                        >
                          <span className='dayss'>
                            {isNaN(parseInt(item.transitTime))
                              ? 'N/A'
                              : item.transitTime === '0'
                              ? 'N/A'
                              : parseInt(item.transitTime)}
                          </span>
                        </Typography>
                        <Typography
                          variant='body1'
                          className='text-black fw-bold'
                          style={{ fontSize: '13px', textAlign: 'center' }}
                        >
                          Service Day(s)
                        </Typography>
                      </Col>

                      <Col>
                        <Button
                          variant='text'
                          startIcon={<RemoveRedEyeIcon />}
                          onClick={() => handleViewRate(item)}
                          className='fw-bold'
                          style={{
                            textTransform: 'none',
                            textDecoration: 'underline',
                            fontSize: '13px'
                          }}
                        >
                          Quote Detail
                        </Button>
                      </Col>
                      {shipTypeValue !== 'Parcel' && (
                        <Col style={{ position: 'relative' }}>
                          <Button
                            variant='text'
                            startIcon={<ExploreIcon />}
                            onClick={() => handleViewRate1(item)}
                            className='fw-bold'
                            style={{
                              textTransform: 'none',
                              textDecoration: 'underline',
                              fontSize: '13px'
                            }}
                          >
                            Terminal Info
                          </Button>
                          {item.isGuaranteedDelviery && (
                            <img
                              alt=''
                              style={{
                                position: 'absolute',
                                right: 8,
                                top: -10
                              }}
                              src={GuaranteeIcon}
                              width={45}
                              height={45}
                            />
                          )}
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}

              {checked &&
                ErrMsgArray?.map((item, index) => (
                  <div
                    className='filter-item errs  p-2'
                    style={{ width: '100%' }}
                  >
                    <Row className='table-card'>
                      <Col
                        xs={2}
                        className='d-flex flex-row h-100'
                        style={{ alignItems: 'center' }}
                      >
                        <img
                          alt=''
                          className='table-card-logo'
                          src={item.logo.logo}
                          width={90}
                        />
                      </Col>
                      <Col
                        className='d-flex flex-row h-100'
                        style={{ alignItems: 'center' }}
                      >
                        <RateErrorTooltip
                          title={item.msg.length > 90 ? item.msg : ''}
                          placement='top'
                          arrow
                          style={{ backgroundColor: '#c21919', color: 'white' }}
                        >
                          <Typography
                            variant='h6'
                            className=' text-white px-2 py-1'
                            style={{
                              fontSize: '13px',
                              backgroundColor: '#c21919',
                              borderRadius: '6px'
                            }}
                          >
                            {item.msg.substring(0, 100)}
                          </Typography>
                        </RateErrorTooltip>
                      </Col>
                    </Row>
                  </div>
                ))}
            </>
          )}
          {noAssignedCarriers && (
            <div style={{ marginTop: '20vh' }}>
              <div class='alert alert-info' role='alert'>
                There are no configured carriers for selected Location!
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuickQuoteTabPanel;

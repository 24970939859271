import React from 'react';

// without lazy import
import SignOut from '../pages/ExtraPages/SignOut';
import TrackShipmentDetails from '../pages/TrackShipment/TrackShipmentDetails';
import MyProfile from '../pages/Profile/MyProfile/MyProfile';
import Layout2 from '../pages/Home/Layout2/Layout2';
import Agencies from '../pages/Agencies/Agencies';
import Carriers from '../pages/Carriers/Carriers';
import GetQuotes from '../pages/GetQoute/GetQuotes';
import QuickQuote from '../pages/GetQoute/QuickQuote';
import QuickQuoteRates from '../pages/GetRate/QuickQuoteRates';
import DispatchShipment from '../pages/DispatchShipment/DispatchShipment';
import BookShipmentReview from '../pages/PickupShipment/PickupShipmentLTL';
import SignIn from '../pages/ExtraPages/SignIn';
import ResetPassword from '../pages/ExtraPages/ResetPassword';
import UpdatePassword from '../pages/ExtraPages/UpdatePassword';
import LockScreenComponent from '../pages/LockScreen/LockScreen';
import SignUp from '../pages/ExtraPages/SignUp';
import ConfirmationCode from '../pages/ExtraPages/ConfirmationCode';
import ViewShipment from '../pages/ViewShipment/ViewShipment';

import KanBan from '../pages/PickupShipment/KanBan';
import Locations from '../pages/Locations/ViewLocations';
import AddLocations from '../pages/Locations/AddLocations';
import BookShipmentReviewTL from '../pages/PickupShipment/PickupShipentTL';
// import ImportCsv from '../pages/ImportCsv/ImportCsv';

import AllUsers from '../pages/Users/AllUsers';
import Settings from '../pages/Settings/settings';
import ImportCsv from '../pages/ImportCsv/ImportCsv';
import CreateCarrier from '../pages/Carriers/CreateCarrier';
import CarrierManagerArea from '../components/CarrierManagerPage/CarrierManagerArea';
import Dashboard from '../pages/Dashboard/Dashboard';
// import ExampleWithReactQueryProvider from '../pages/Home/Layout2/ShipmentsDemo';
// import InfiniteScrollTable from '../pages/Home/Layout2/ShipmentsDemo';
import ApexChart from '../pages/Dashboard/AppexDashboard';
import BookParcelShipment from '../pages/PickupShipment/pickupShipmentParcel';
import FalconDashboard from '../pages/FalconDashboard';
import PowerBiDashboard from '../pages/PowerBiDashboard/PowerBi';
import ClientIntegrations from '../pages/AppRegisteration/AppRegisteration';
import AppRegisteration from '../pages/AppRegisteration/AppRegisteration';
// import { Example } from '../pages/Home/Layout2/ShipmentsDemo';

const userRoutes = [
  // { path: '/falcon-dashboard', component:  },
  { path: '/dashboard', component: PowerBiDashboard },

  // { path: '/dashboard', component: Dashboard },
  { path: '/appex-dashboard', component: ApexChart },
  { path: '/track-shipment', component: TrackShipmentDetails },
  { path: '/user-profile', component: MyProfile },
  { path: '/manage-shipments', component: Layout2 },
  // { path: '/demo-shipments', component: Example },
  { path: '/agencies', component: Agencies },
  { path: '/carriers', component: Carriers },
  { path: '/app-registration', component: AppRegisteration },
  { path: '/create-carrier', component: CreateCarrier },
  { path: '/view-locations', component: Locations },
  { path: '/add-locations', component: AddLocations },
  { path: '/get-quote', component: GetQuotes },
  { path: '/quick-quote', component: QuickQuote },
  { path: '/get-rates', component: QuickQuoteRates },
  { path: '/dispatch', component: DispatchShipment },
  { path: '/bookshipment', component: BookShipmentReview },
  { path: '/view-shipment', component: ViewShipment },
  { path: '/users', component: AllUsers },
  { path: '/kanban', component: KanBan },
  { path: '/settings', component: Settings },
  { path: '/bookshipmentLTL', component: BookShipmentReview },
  { path: '/bookshipmentTL', component: BookShipmentReviewTL },
  { path: '/bookshipmentParcel', component: BookParcelShipment },
  { path: '/import-csv', component: ImportCsv },
  { path: '/', component: Layout2 }

  // { path: "*", component: Error404 },
];

const authRoutes = [
  // { path: "/", component: SignIn },
  { path: '/agency-signin', component: SignIn },
  { path: '/agency-resetpassword', component: ResetPassword },
  { path: '/update-password', component: UpdatePassword },
  { path: '/signout', component: SignOut },
  { path: '/agency-locked-screen', component: LockScreenComponent },
  { path: '/signup', component: SignIn },
  { path: '/confirmation', component: ConfirmationCode },
  { path: '/carrier-manager', component: CarrierManagerArea }
  // { path: "*", component: Error404 },
];
export { userRoutes, authRoutes };

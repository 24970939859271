import React, { useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import { Col, Label, Row, Button } from 'reactstrap';
import {
  CardContent,
  Card,
  FormControlLabel,
  InputAdornment,
  Paper,
  TextField,
  Box,
  createFilterOptions,
  Grid,
  Chip,
  CardActionArea,
  CardActions
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import MergeTypeIcon from '@material-ui/icons/LocalShippingSharp';
import LocationIcon from '@material-ui/icons/PersonPinSharp';
import PaymentIcon from '@material-ui/icons/PaymentSharp';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import { GetAagencyUserDetails } from '../../apis/Agency';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setClassDimensionsButton } from '../../Redux/ClassDimensionsBtnSlice';
import USALocations from '../../apis/USALocations.json';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import { setClassRows } from '../../Redux/QuickQuoteAddedRows';
import { setDimensionRows } from '../../Redux/QuickQuoteDimenionsAddedRows';
import {
  setClassWeight,
  setDimensionsWeight
} from '../../Redux/QuickQuoteTotalWeightSlice';
import { base_url } from '../../apis/constants';
import ReactQuill from 'react-quill';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function QuickQuote(props) {
  const today = new Date().toISOString().split('T')[0];
  const [shipCity, setShipCity] = useState([]);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);

  const handleUSAddresses = () => {
    let tempCity = [];
    for (let index = 0; index < USALocations.length; index++) {
      const City = USALocations[index].city;
      const State = USALocations[index].state;
      const ZipCode = USALocations[index].zip_code;

      tempCity.push(City + ' ' + State + ' ' + ZipCode);
    }
    setShipCity(tempCity);
  };
  useEffect(() => {
    handleUSAddresses();
  }, []);

  var layoutMode = document.body.getAttribute('data-layout-mode');
  const selectLocations = useRef(null);
  const selectShipFrom = useRef(null);
  const selectShipTo = useRef(null);
  const enterWeight = useRef(null);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const UserDetails = useSelector(state => state.userDetails);
  const viewShipmentsData = useSelector(state => state.getShipments);
  const SelectedRates = useSelector(state => state.quickQuoteRate);
  const AddedClassRows = useSelector(state => state.newAddedPersistedRows);
  const AddedDimensionsRows = useSelector(
    state => state.newAddedPersistedDimensionsRows
  );
  const getPickupOption = option =>
    SelectedRates?.pickupOptions?.[option] ?? false;

  const getDeliveryOption = option =>
    SelectedRates?.deliveryOptions?.[option] ?? false;

  const ServicesSwitchChecks = useSelector(
    state => state.quickQuoteServicesSlice
  );
  let PersistedPaymentType = SelectedRates?.paymentType;
  // const SelectedRatesPaymentType =
  //   PaymentType === '0'
  //     ? 'Outbound Prepaid'
  //     : PaymentType === '1'
  //     ? 'Inbound Collect'
  //     : 'Third Party';
  const ClassDimBtn = useSelector(state => state.classDimensionsBtn);
  const TotalClassPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.ClassWeight
  );
  const TotalDimensionsPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.DimensionsWeight
  );
  const selectedTableRowID = props?.location.state;
  const dispatch = useDispatch();
  // const useStyles = makeStyles({
  //   inputRoot: {
  //     '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
  //       '-webkit-appearance': 'none',
  //       margin: 0
  //     },
  //     '& input[type=number]': {
  //       '-moz-appearance': 'textfield'
  //     }
  //   }
  // });
  const classes = useStyles();
  const [shipLocationValue, setShipLocationValue] = useState(
    SelectedRates?.location ? SelectedRates?.location : ''
  );
  //pickup switch states

  const [insidePickupSwitch, setInsidePickupSwitch] = useState(
    getPickupOption('inside')
  );
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(
    getPickupOption('liftGateServices')
  );
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(
    getPickupOption('residential')
  );
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] = useState(
    getPickupOption('limitedAccess')
  );
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(
    getPickupOption('secureAccess')
  );
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(getPickupOption('freezeProtection'));
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(
    getPickupOption('overLength')
  );

  //delivery switch states

  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(
    getDeliveryOption('inside')
  );
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(
    getDeliveryOption('liftGateServices')
  );
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] = useState(
    getDeliveryOption('residential')
  );
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(
    getDeliveryOption('limitedAccess')
  );
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] = useState(
    getDeliveryOption('secureAccess')
  );

  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(getDeliveryOption('callBeforeDelivery'));

  const LTLPaymentTypeArr = [
    'Outbound Prepaid',
    'Inbound Collect',
    'Third Party'
  ];
  const parcelPaymentTypeArr = ['Sender', 'Receiver', 'Third Party'];
  const [shipTypeOptions, setShipTypeOptions] = useState([]);
  const [hazmatSwitch, setHazmatSwitch] = useState(
    SelectedRates?.isHazmat ? SelectedRates?.isHazmat : false
  );
  const [stackableSwitch, setStackableSwitch] = useState(false);
  const [pickUpChecked, setPickUpChecked] = useState(
    ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      ? ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      : false
  );
  const [overLengthValue, setOverLengthValue] = useState(
    SelectedRates.pickupOptions?.overLengthValue
      ? SelectedRates?.pickupOptions?.overLengthValue
      : ''
  );
  const [deliveryChecked, setDeliveryChecked] = useState(
    ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      ? ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      : false
  );

  // const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [shipmentDate, setShipmentDate] = useState(materialDateInput);
  const DataToShow = viewShipmentsData.ShipmentDetails[selectedTableRowID];
  // const [loading, setLoading] = useState(false);
  const ShipmentType = DataToShow?.shipmentType;
  const ShipTypeArr = ['LTL', 'Parcel'];
  // const ShipTypeArr = ['LTL'];

  const LTLUnitTypeArr = ['Pallet', 'Tote', 'Box', 'Drum'];
  const ParcelUnitTypeArr = ['Box', 'Envelope'];

  const [shipTypeValue, setShipTypeValue] = useState(
    SelectedRates?.shipmentType ? SelectedRates?.shipmentType : ShipTypeArr[0]
  );
  const [unitTypeValue, setUnitTypeValue] = useState(LTLUnitTypeArr[0]);

  const [validationErrors, setValidationErrors] = useState({});

  const [shipLocation, setShipLocation] = useState([]);
  const [shipLocationToShow, setShipLocationToShow] = useState([]);
  const [shipLocationLoader, setShipLocationLoader] = useState(false);

  const [shipLocationID, setShipLocationID] = useState(
    SelectedRates?.locationId ? SelectedRates?.locationId : 0
  );
  console.log(SelectedRates, 'SelectedRates');
  const [paymentTypeValue, setPaymentTypeValue] = useState(
    PersistedPaymentType === '0'
      ? SelectedRates.shipmentType === 'Parcel'
        ? 'Sender'
        : 'Outbound Prepaid'
      : PersistedPaymentType === '1'
      ? SelectedRates.shipmentType === 'Parcel'
        ? 'Receiver'
        : 'Inbound Collect'
      : PersistedPaymentType === '2'
      ? 'Third Party'
      : 'Outbound Prepaid'
  );
  const [backBtnClicked, setBackBtnClicked] = useState(false);
  const [quickWeightLBS, setQuickWeightLBS] = useState('');
  const [finalCommodityInfoApi, setFinalCommodityInfoApi] = useState(
    SelectedRates?.commodityInfo ? SelectedRates?.commodityInfo : []
  );
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];
  const [classArrayValue, setClassArrayValue] = useState(ClassArray[0]);
  const CommodityInfoArray = [
    {
      WeightLBS: '',
      Class: classArrayValue,
      NMFCnumber: ''
    }
  ];
  const [NMFCnumber, setNMFCnumber] = useState('');
  const [dimensionsWeightLBS, setDimensionsWeightLBS] = useState('');
  const [dimensionsWeightLBSErr, setDimensionsWeightLBSErr] = useState(false);
  const [nmbOfunitsErr, setNmbOfunitsErr] = useState(false);
  const [nmbOfunits, setNmbOfunits] = useState('');
  const [dimensions, setDimensions] = useState({
    Lenght: '',
    Width: '',
    Height: ''
  });
  const [dimensionsValueErr, setDimensionsValueErr] = useState({
    Lenght: false,
    Width: false,
    Height: false
  });
  const [CODAmount, setCODAmount] = useState(
    SelectedRates?.codAmount ? SelectedRates.codAmount : ''
  );
  const [DeclaredValue, setDeclaredValue] = useState(
    SelectedRates?.declaredValue ? SelectedRates.declaredValue : ''
  );
  const [getRateLoading, setGetRateLoading] = useState(false);
  const [selectClassErr, setSelectClassErr] = useState(false);
  const [dimensionsErr, setDimensionsErr] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [shipFromValue, setShipFromValue] = useState('');
  const [shipToValue, setShipToValue] = useState('');
  const [freightClass, setFreightClass] = useState('');
  const [UnitTypeName, setUnitTypeName] = useState('Pallet');
  const [newAddedDimensionsRow, setNewAddedDimensionsRow] = useState([
    {
      Class: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].freightClass
        : '50',
      WeightLBS: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].weight?.value
        : '',
      UnitTypeValue: SelectedRates?.commodityInfo?.[0]?.packaging
        ? SelectedRates?.commodityInfo?.[0]?.packaging
        : LTLUnitTypeArr[0],
      nmfc: '',
      NmbOfUnits: SelectedRates?.commodityInfo?.[0]?.pieces
        ? SelectedRates?.commodityInfo?.[0].pieces
        : '1',
      StackAble: SelectedRates?.commodityInfo?.[0].dimensions?.isStackable
        ? SelectedRates?.commodityInfo?.[0].dimensions?.isStackable
        : false,
      Length: SelectedRates?.commodityInfo?.[0].dimensions?.length
        ? SelectedRates?.commodityInfo?.[0].dimensions.length
        : '',
      Width: SelectedRates?.commodityInfo?.[0].dimensions?.width
        ? SelectedRates?.commodityInfo?.[0].dimensions.width
        : '',
      Height: SelectedRates?.commodityInfo?.[0].dimensions?.height
        ? SelectedRates?.commodityInfo?.[0].dimensions?.height
        : ''
    }
  ]);

  const [newAddedClassRow, setNewAddedClassRow] = useState([
    {
      WeightLBS: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].weight?.value
        : '',
      Class: ClassArray[0],
      NMFCnumber: ''
    }
  ]);
  const [totalLBSWeightClass, setTotalLBSWeightClass] = useState('');

  const [totalLBSWeightDimensions, setTotalLBSWeightDimensions] = useState('');
  const [totalDimensionsWeightSum, setTotalDimensionsWeightSum] = useState(
    TotalDimensionsPersistedWeight
  );
  const [totalClassWeightSum, setTotalClassWeightSum] = useState(
    TotalClassPersistedWeight
  );

  const [alignment, setAlignment] = useState(
    ClassDimBtn ? ClassDimBtn : 'Class'
  );
  const [isOverLength, setIsOverLength] = useState(
    SelectedRates.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  const DetailsArr = [];
  const ShipFrom =
    SelectedRates?.shipFrom?.city +
    ' ' +
    SelectedRates?.shipFrom?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipFrom?.postalCode;

  const ShipTo =
    SelectedRates?.shipTo?.city +
    ' ' +
    SelectedRates?.shipTo?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipTo?.postalCode;
  const WeightLBS = DataToShow?.details[0].grossWeight;
  const WeightPieces = DataToShow?.details[0].pieces;
  const UserLoggedInEmail = useSelector(state => state.userDetails.email);
  const [locationHoveredValue, setLocationHoveredValue] = useState(null);
  const [locationInputChange, setLocationInputChange] = useState(false);
  const [paymentTypeHoveredValue, setPaymentTypeHoveredValue] = useState(null);
  const [paymentTypeInputChange, setPaymentTypeInputChange] = useState(false);
  const [shipFromHoveredValue, setShipFromHoveredValue] = useState(null);
  const [shipFromInputChange, setShipFromInputChange] = useState(false);
  const [shipToHoveredValue, setShipToHoveredValue] = useState(null);
  const [shipToInputChange, setShipToInputChange] = useState(false);
  const [freightClassHoveredValue, setFreightClassHoveredValue] =
    useState(null);
  const [unitTypeHoveredValue, setUnitTypeHoveredValue] = useState(null);
  // useEffect(() => {
  //   // dispatch(setQuickQuoteRateValues(''));
  //   // Cleanup function
  //   return () => {
  //     // Clear the values when the component is unmounted or user navigates away
  //     dispatch(setQuickQuoteRateValues(''));
  //   };
  // }, []); // Empty dependency array to run the effect only once on mount

  // Add another useEffect for handling page refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the values when the page is being refreshed
      dispatch(setQuickQuoteRateValues(''));
      dispatch(setQuickQuotePickUpCheckedRedux(false));
      dispatch(setQuickQuoteDeliveryCheckedRedux(false));
      // dispatch(setClassDimensionsButton(alignment));
      // dispatch(setClassRows(newAddedClassRow));
      // dispatch(setDimensionRows(newAddedDimensionsRow));
      // dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(''));
    };

    // Attach the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function for removing the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let ToalWeightLBS = 0;
    if (AddedClassRows) {
      for (let i = 0; i < AddedClassRows.length; i++) {
        ToalWeightLBS += parseInt(AddedClassRows[i].WeightLBS);
      }
      setTotalLBSWeightClass(ToalWeightLBS);
    }
  }, []);

  useEffect(() => {
    let ToalWeightLBS = 0;
    if (AddedDimensionsRows) {
      for (let i = 0; i < AddedDimensionsRows.length; i++) {
        ToalWeightLBS += parseInt(AddedDimensionsRows[i].WeightLBS);
      }
      setTotalLBSWeightDimensions(ToalWeightLBS);
    }
  }, []);

  useEffect(() => {
    let totalWeight;
    if (SelectedRates?.commodityInfo?.[0]?.weight?.value) {
      const copy1 = JSON.parse(JSON.stringify(AddedClassRows));
      setNewAddedClassRow(copy1);
      const copy2 = JSON.parse(JSON.stringify(AddedDimensionsRows));

      setNewAddedDimensionsRow(copy2);

      if (alignment === 'Dimensions') {
        setTotalDimensionsWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }

      if (alignment === 'Class') {
        setTotalClassWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }
    }
    let FinalObj = {};
    if (
      !ShipFrom.includes('undefined') &&
      (paymentTypeValue === 'Third Party' ||
        paymentTypeValue === 'Inbound Collect')
    ) {
      // const tempData = ShipFrom.split(' ');
      // const City = tempData[0];
      // const State = tempData[1];
      // const ZipCode = tempData[2];
      // FinalObj = {
      //   City,
      //   State,
      //   ZipCode
      // };

      const parseAddress = address => {
        const addressParts = address.split(' ');
        let city = '';
        let state = '';
        let zipCode = '';

        for (let i = 0; i < addressParts.length; i++) {
          const part = addressParts[i];

          // Check if the part is a state code (e.g., TX)
          if (part.length === 2 && /^[A-Za-z]+$/.test(part)) {
            state = part;
          }
          // Check if the part is a zip code
          else if (part.length === 5 && /^\d+$/.test(part)) {
            zipCode = part;
          }
          // Assume it's the city if not a state code or zip code
          else {
            city += part + ' ';
          }
        }

        // Trim any extra spaces from the city
        city = city.trim();

        return { city, state, zip: zipCode };
      };
      const tempData = ShipFrom;
      const parsedAddress = parseAddress(tempData);
      const City = parsedAddress.city;
      const State = parsedAddress.state;
      const ZipCode = parsedAddress.zip;
      FinalObj = {
        City,
        State,
        ZipCode
      };
      setShipFromFinalAddress(FinalObj);
    }

    if (
      !ShipTo.includes('undefined') &&
      paymentTypeValue !== 'Inbound Collect'
    ) {
      const parseAddress = address => {
        const addressParts = address.split(' ');
        let city = '';
        let state = '';
        let zipCode = '';

        for (let i = 0; i < addressParts.length; i++) {
          const part = addressParts[i];

          // Check if the part is a state code (e.g., TX)
          if (part.length === 2 && /^[A-Za-z]+$/.test(part)) {
            state = part;
          }
          // Check if the part is a zip code
          else if (part.length === 5 && /^\d+$/.test(part)) {
            zipCode = part;
          }
          // Assume it's the city if not a state code or zip code
          else {
            city += part + ' ';
          }
        }

        // Trim any extra spaces from the city
        city = city.trim();

        return { city, state, zip: zipCode };
      };
      const tempData = ShipTo;
      const parsedAddress = parseAddress(tempData);
      const City = parsedAddress.city;
      const State = parsedAddress.state;
      const ZipCode = parsedAddress.zip;
      FinalObj = {
        City,
        State,
        ZipCode
      };
      //   const tempData = ShipTo.split(' ');
      // console.log(ShipTo,'Outbound')

      // const City = tempData[0];
      // const State = tempData[1];
      // const ZipCode = tempData[2];
      // FinalObj = {
      //   City,
      //   State,
      //   ZipCode
      // };
      setShipToFinalAddress(FinalObj);
    }
    // else {
    //   const tempData = ShipTo.split(' ');
    //   const City = tempData[0];
    //   const State = tempData[1];
    //   const ZipCode = tempData[2];
    //   FinalObj = {
    //     City,
    //     State,
    //     ZipCode
    //   };
    //   setShipToFinalAddress(FinalObj);
    // }
  }, []);

  const handleDimensionChange = (name, dimensionsVal) => {
    if (name === 'length') {
      setDimensions({ ...dimensions, Lenght: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Lenght: false });
    } else if (name === 'width') {
      setDimensions({ ...dimensions, Width: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Width: false });
    } else setDimensions({ ...dimensions, Height: dimensionsVal });
    setDimensionsValueErr({ ...dimensionsValueErr, Height: false });
  };

  const handleGetRates = e => {
    e.preventDefault();

    if (dimensionsErr) {
      if (!dimensionsWeightLBS) {
        setDimensionsWeightLBSErr(true);
      }
      if (!nmbOfunits) {
        setNmbOfunitsErr(true);
      }
      if (!dimensions.Lenght) {
        setDimensionsValueErr({ ...dimensionsValueErr, Lenght: true });
      }
      if (!dimensions.Width) {
        setDimensionsValueErr({ ...dimensionsValueErr, Width: true });
      }
      if (!dimensions.Height) {
        setDimensionsValueErr({ ...dimensionsValueErr, Height: true });
      }
    }
    if (!classArrayValue) {
      setSelectClassErr(true);
    } else if (classArrayValue) {
      setSelectClassErr(false);
      // setGetRateLoading(true);
      let OutbondShipper;
      let OutbondReciever;
      let PersistedShipFrom;
      let PersistedShipTo;

      let ApiCityFrom = '';
      let ApiStateFrom = '';
      let ApiZipFrom = '';
      if (
        (paymentTypeValue === 'Outbound Prepaid' ||
          paymentTypeValue === 'Sender') &&
        !shipFromFinalAddress.City
      ) {
        OutbondShipper = shipFromFinalAddress?.split(' ');
        ApiCityFrom =
          paymentTypeValue === 'Outbound Prepaid' ||
          paymentTypeValue === 'Sender'
            ? OutbondShipper[0]
            : shipFromFinalAddress.City;
        ApiStateFrom =
          paymentTypeValue === 'Outbound Prepaid' ||
          paymentTypeValue === 'Sender'
            ? OutbondShipper[1]
            : shipFromFinalAddress.State;
        ApiZipFrom =
          paymentTypeValue === 'Outbound Prepaid' ||
          paymentTypeValue === 'Sender'
            ? OutbondShipper[2]
            : shipFromFinalAddress.ZipCode;
      } else {
        ApiCityFrom = shipFromFinalAddress.City;
        ApiStateFrom = shipFromFinalAddress.State;
        ApiZipFrom = shipFromFinalAddress.ZipCode;
      }

      let ApiCityTo = '';
      let ApiStateTo = '';
      let ApiZipTo = '';
      if (
        (paymentTypeValue === 'Inbound Collect' ||
          paymentTypeValue === 'Receiver') &&
        !shipToFinalAddress.City
      ) {
        OutbondReciever = shipToFinalAddress?.split(' ');

        ApiCityTo =
          paymentTypeValue === 'Inbound Collect' ||
          paymentTypeValue === 'Receiver'
            ? OutbondReciever[0]
            : shipToFinalAddress.City;
        ApiStateTo =
          paymentTypeValue === 'Inbound Collect' ||
          paymentTypeValue === 'Receiver'
            ? OutbondReciever[1]
            : shipToFinalAddress.State;
        ApiZipTo =
          paymentTypeValue === 'Inbound Collect' ||
          paymentTypeValue === 'Receiver'
            ? OutbondReciever[2]
            : shipToFinalAddress.ZipCode;
      } else {
        ApiCityTo = shipToFinalAddress.City;
        ApiStateTo = shipToFinalAddress.State;
        ApiZipTo = shipToFinalAddress.ZipCode;
      }

      let obj = {
        shipmentType: shipTypeValue,
        location: shipLocationValue,
        paymentType:
          paymentTypeValue === 'Outbound Prepaid' ||
          paymentTypeValue === 'Sender'
            ? '0'
            : paymentTypeValue === 'Inbound Collect' ||
              paymentTypeValue === 'Receiver'
            ? '1'
            : '2',
        shipFrom: {
          city: ApiCityFrom,
          stateOrProvinceCode: ApiStateFrom,
          postalCode: ApiZipFrom,
          countryCode: 'USA',
          residential: true,
          streetLines: ApiCityFrom + ' ' + ApiStateFrom + ' ' + ApiZipFrom
        },
        shipTo: {
          city: ApiCityTo,
          stateOrProvinceCode: ApiStateTo,
          postalCode: ApiZipTo,
          countryCode: 'USA',
          residential: true,
          streetLines: ApiCityTo + ' ' + ApiStateTo + ' ' + ApiZipTo
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: handlePushAddedCommodityInfo(),
        // commodityInfo: [
        //   {
        //     freightClass: newAddedDimensionsRow[0].Class
        //       ? newAddedDimensionsRow[0].Class
        //       : '50',
        //     packaging: newAddedDimensionsRow[0].UnitTypeValue
        //       ? newAddedDimensionsRow[0].UnitTypeValue
        //       : 'Pallet',
        //     pieces: newAddedDimensionsRow[0].NmbOfUnits
        //       ? newAddedDimensionsRow[0].NmbOfUnits
        //       : 0,
        //     weight: {
        //       units: 'lbs',
        //       value: newAddedDimensionsRow[0].WeightLBS
        //     },
        //     nmfc: newAddedClassRow[0].NMFCnumber
        //       ? newAddedClassRow[0].NMFCnumber
        //       : '',
        //     dimensions: {
        //       length: newAddedDimensionsRow[0].Length
        //         ? newAddedDimensionsRow[0].Length
        //         : 0,
        //       width: newAddedDimensionsRow[0].Width
        //         ? newAddedDimensionsRow[0].Width
        //         : 0,
        //       height: newAddedDimensionsRow[0].Height
        //         ? newAddedDimensionsRow[0].Height
        //         : 0,
        //       dimensionsUom: 'inches',
        //       isStackable: newAddedDimensionsRow[0].StackAble
        //     }
        //   }
        // ],
        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          overLengthValue: 0,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? parseInt(CODAmount) : 0,
        declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
        agencyId: AgencyID ? AgencyID : 0,
        isGuaranteed: false,
        locationId: shipLocationID
      };

      //   dispatch(setPickUpCheckedRedux(pickUpChecked));
      //   dispatch(setDeliveryCheckedRedux(deliveryChecked));
      //   dispatch(setRateValues(obj));

      let deepClone = JSON.parse(JSON.stringify(obj));
      // let newObj = { ...obj, };
      deepClone.shipFrom.countryCode = 'US';
      deepClone.shipTo.countryCode = 'US';
      validateAutocompletes(obj, deepClone);
    }
  };

  const handlePickUpServicesChange = switchName => {
    if (switchName === 'hazmat') {
      setHazmatSwitch(c => !c);
    } else if (switchName === 'Stackable') {
      setStackableSwitch(c => !c);
    } else if (switchName === 'Inside Pickup') {
      setInsidePickupSwitch(c => !c);
    } else if (switchName === 'Inside Lift Gate Services') {
      setLiftgatePickupSwitch(c => !c);
    } else if (switchName === 'Residential Pickup') {
      setResidentialPickupSwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesPickupSwitch(c => !c);
    } else if (switchName === 'Freeze Protection') {
      setFreezeProtectionPickupSwitch(c => !c);
    } else if (switchName === 'Overlength') {
      setOverlengthPickupSwitch(c => !c);
      setIsOverLength(!isOverLength);
      setOverLengthValue('');
    } else setLimitedPickupAccessSwitch(c => !c);
  };

  const handleDeliveryServicesChange = switchName => {
    if (switchName === 'Inside Delivery') {
      setInsideDeliverySwitch(c => !c);
    } else if (switchName === 'Liftgate Delivery') {
      setLiftgateDeliverySwitch(c => !c);
    } else if (switchName === 'Residential Delivery') {
      setResidentialDeliverySwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesDeliverySwitch(c => !c);
    } else if (switchName === 'Call Before Delivery') {
      setCallBeforeDeliveryServicesSwitch(c => !c);
    } else setLimitedDeliverySwitch(c => !c);
  };

  const checkPickupStatus = () => {
    if (pickUpChecked) {
      setInsidePickupSwitch(false);
      setLiftgatePickupSwitch(false);
      setResidentialPickupSwitch(false);
      setSecureAccesPickupSwitch(false);
      setFreezeProtectionPickupSwitch(false);
      setOverlengthPickupSwitch(false);
      setLimitedPickupAccessSwitch(false);
      setIsOverLength(false);

      //   dispatch(setRateValues(obj));
    }
  };
  const checkDeliveryStatus = () => {
    if (deliveryChecked) {
      setInsideDeliverySwitch(false);
      setLiftgateDeliverySwitch(false);
      setResidentialDeliverySwitch(false);
      setSecureAccesDeliverySwitch(false);
      setCallBeforeDeliveryServicesSwitch(false);
      setLimitedDeliverySwitch(false);

      //   dispatch(setRateValues(obj));
    }
  };
  const handlePickupServices = v => {
    setPickUpChecked(c => !c);
    checkPickupStatus();
    // setDeliveryChecked(false);
  };

  const handleDeliveryServices = () => {
    setDeliveryChecked(c => !c);
    checkDeliveryStatus();
    // setPickUpChecked(false);
  };

  const handleSetShipLocation = arr => {
    const temp = [];
    const array = arr.assignedLocations;
    for (let i = 0; i < array.length; i++) {
      const Element =
        array[i].name +
        ': ' +
        array[i].city +
        ' ' +
        array[i].stateOrProvinceCode +
        ' ' +
        array[i].postalCode;
      const LocationId = array[i].id;
      temp.push({ location: Element, id: LocationId });
    }
    setShipLocationLoader(false);
    setShipLocation(temp.reverse());
  };

  useEffect(() => {
    setShipLocationLoader(true);
    GetAagencyUserDetails(UserLoggedInEmail)
      .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
      .catch(err => console.log(err));
    // GetLocations(AgencyID)
    //   .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
    //   .catch(err => console.log(err));
  }, []);

  const handleCheckAddedRow = () => {};
  const handleKeyPress = event => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const handleDimensionsInputChange = (inputName, index, event) => {
    var filteredClass = [];
    var filteredUnitType = [];

    let totalWeight = 0;
    let TotalWeightSum;
    const maxLimit = 14999;
    const maxLimitParcel = 150;
    const values = [...newAddedDimensionsRow];
    if (inputName === 'WeightLBS') {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      if (shipTypeValue === 'Parcel') {
        if (parseInt(inputValue, 10) > maxLimitParcel) {
          const updatedErrors = {
            ...validationErrors,
            newAddedDimensionsRow: 'Maximum weight limit is 150 lbs'
          };
          setValidationErrors(updatedErrors);
        } else {
          const updatedErrors = {
            ...validationErrors,
            newAddedDimensionsRow: null
          };
          setValidationErrors(updatedErrors);
          values[index].WeightLBS = inputValue;

          for (let index = 0; index < newAddedDimensionsRow.length; index++) {
            if (newAddedDimensionsRow[index].WeightLBS !== '') {
              totalWeight += parseInt(newAddedDimensionsRow[index].WeightLBS);
            }
          }
          setTotalLBSWeightDimensions(totalWeight);
          TotalWeightSum = totalLBSWeightClass
            ? parseInt(totalLBSWeightClass) + totalWeight
            : totalWeight;
          setTotalDimensionsWeightSum(totalWeight);
          handlePushAddedCommodityInfo(totalWeight);
        }
      } else if (
        shipTypeValue === 'LTL' &&
        parseInt(inputValue, 10) > maxLimit
      ) {
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: 'Maximum weight limit is 14999'
        };
        setValidationErrors(updatedErrors);
      } else {
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: null
        };
        setValidationErrors(updatedErrors);
        values[index].WeightLBS = inputValue;

        for (let index = 0; index < newAddedDimensionsRow.length; index++) {
          if (newAddedDimensionsRow[index].WeightLBS !== '') {
            totalWeight += parseInt(newAddedDimensionsRow[index].WeightLBS);
          }
        }
        setTotalLBSWeightDimensions(totalWeight);
        TotalWeightSum = totalLBSWeightClass
          ? parseInt(totalLBSWeightClass) + totalWeight
          : totalWeight;
        setTotalDimensionsWeightSum(totalWeight);
        handlePushAddedCommodityInfo(totalWeight, 'totalWeight');
      }
    } else if (inputName === 'Class') {
      if (event !== null) {
        filteredClass = ClassArray.filter(option =>
          option.toLowerCase().includes(event?.toLowerCase())
        );
      }
      setFreightClassHoveredValue(filteredClass[0]);
      // values[index].Class = event;
      if (!values[index]) {
        // If values[index] is undefined, create a new object
        values[index] = {};
      }

      values[index].Class = event;
    } else if (inputName === 'NmbOfUnits') {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      handlePushAddedCommodityInfo(inputValue, 'NmbOfUnits');
      // if (parseInt(inputValue, 10) >= 0) {
      //   values[index].NmbOfUnits = inputValue;
      // }
      if (!isNaN(inputValue) && parseFloat(inputValue) > 0) {
        values[index].NmbOfUnits = inputValue;
        const updatedErrors = {
          ...validationErrors,
          numberOfUnits: null
        };
        setValidationErrors(updatedErrors);
      } else {
        values[index].NmbOfUnits = '';
      }
    } else if (inputName === 'UnitType') {
      if (event !== null) {
        filteredUnitType =
          shipTypeValue === 'LTL'
            ? LTLUnitTypeArr.filter(option =>
                option.toLowerCase().includes(event?.toLowerCase())
              )
            : ParcelUnitTypeArr.filter(option =>
                option.toLowerCase().includes(event?.toLowerCase())
              );
      }
      setUnitTypeHoveredValue(filteredUnitType[0]);
      // setUnitTypeName('');
      // values[index].UnitTypeValue = event;
      if (!values[index]) {
        // If values[index] is undefined, create a new object
        values[index] = {};
      }

      setUnitTypeName('');
      values[index].UnitTypeValue = event;
      handlePushAddedCommodityInfo(event, 'UnitType');
    } else if (inputName === 'Stackable') {
      values[index].StackAble = event;
      handlePushAddedCommodityInfo(event, 'Stackable');
    } else if (inputName === 'Length') {
      values[index].Length = event.target.value;
      handlePushAddedCommodityInfo(event.target.value, 'Length');

      const updatedErrors = {
        ...validationErrors,
        PlengthError: null
      };
      setValidationErrors(updatedErrors);
    } else if (inputName === 'Width') {
      handlePushAddedCommodityInfo(event.target.value, 'Width');

      values[index].Width = event.target.value;
      const updatedErrors = {
        ...validationErrors,
        PwidthError: null
      };
      setValidationErrors(updatedErrors);
    } else {
      handlePushAddedCommodityInfo(event.target.value, 'Height');

      values[index].Height = event.target.value;
      const updatedErrors = {
        ...validationErrors,
        PheightError: null
      };
      setValidationErrors(updatedErrors);
    }
    setNewAddedDimensionsRow(values);
  };

  const handlePushAddedCommodityInfo = (removedIndex, valueName) => {
    let CommodityInfoApi = [];
    if (valueName === 'totalWeightMinus') {
      const updatedRows = finalCommodityInfoApi.filter(
        (row, index) => index !== removedIndex
      );
      setFinalCommodityInfoApi(updatedRows);
    } else {
      for (let i = 0; i < newAddedDimensionsRow.length; i++) {
        CommodityInfoApi.push({
          freightClass: newAddedDimensionsRow[i].Class,
          packaging: newAddedDimensionsRow[i].UnitTypeValue,
          pieces: newAddedDimensionsRow[i].NmbOfUnits,
          weight: {
            units: 'lbs',
            value: newAddedDimensionsRow[i].WeightLBS
              ? newAddedDimensionsRow[i].WeightLBS
              : 0
            // valueName === 'totalWeight' ? totalWeight : totalDimensionsWeightSum
          },
          nmfc: newAddedClassRow[0].NMFCnumber,
          dimensions: {
            length: newAddedDimensionsRow[i].Length
              ? newAddedDimensionsRow[i].Length
              : 0,
            width: newAddedDimensionsRow[i].Width
              ? newAddedDimensionsRow[i].Width
              : 0,
            height: newAddedDimensionsRow[i].Height
              ? newAddedDimensionsRow[i].Height
              : 0,
            dimensionsUom: 'inches',
            isStackable: newAddedDimensionsRow[i].StackAble
          }
        });
      }
      // let CommodityInfoApi = [
      //   {
      // freightClass: newAddedDimensionsRow[0].Class,
      // packaging: newAddedDimensionsRow[0].UnitTypeValue,
      // pieces: newAddedDimensionsRow[0].NmbOfUnits,
      // weight: {
      //   units: 'lbs',
      //   value: TotalWeightSum
      // },
      // nmfc: newAddedClassRow[0].NMFCnumber,
      // dimensions: {
      //   length: newAddedDimensionsRow[0].Length,
      //   width: newAddedDimensionsRow[0].Width,
      //   height: newAddedDimensionsRow[0].Height,
      //   dimensionsUom: 'inches',
      //   isStackable: newAddedDimensionsRow[0].StackAble
      // }
      //   }
      // ];
      console.log(CommodityInfoApi, 'CommodityInfoApi');
      setFinalCommodityInfoApi(CommodityInfoApi);
      return CommodityInfoApi;
    }
  };
  // const handleClassInputChange = (inputName, index, event) => {
  //   let totalWeight = 0;
  //   let TotalWeightSum;
  //   const values = [...newAddedClassRow];
  //   if (inputName === 'WeightLBS') {
  //     const updatedErrors = { ...validationErrors, weightLBS: null };
  //     setValidationErrors(updatedErrors);

  //     values[index].WeightLBS = event.target.value;

  //     for (let index = 0; index < newAddedClassRow.length; index++) {
  //       if (newAddedClassRow[index].WeightLBS !== '') {
  //         totalWeight += parseInt(newAddedClassRow[index].WeightLBS);
  //       }
  //     }
  //     setTotalLBSWeightClass(totalWeight);
  //     TotalWeightSum = totalLBSWeightDimensions
  //       ? parseInt(totalLBSWeightDimensions) + totalWeight
  //       : totalWeight;
  //     setTotalClassWeightSum(totalWeight);
  //     handlePushAddedCommodityInfo(totalWeight);
  //   } else if (inputName === 'NMFCnumber') {
  //     values[index].NMFCnumber = event.target.value;
  //   } else if (inputName === 'Class') {
  //     setFreightClass(event);
  //     values[index].Class = event === null ? ClassArray[0] : event;
  //   }

  //   setNewAddedClassRow(values);
  // };

  const handleAddNewRow = e => {
    const temp = [];
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.push({ WeightLBS: '', Class: ClassArray[0], NMFCnumber: '' });
      setNewAddedClassRow(values);
    } else {
      const values = [...newAddedDimensionsRow];
      values.push({
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[0],
        NmbOfUnits: '1',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      });
      setNewAddedDimensionsRow(values);
    }
  };

  const handleSubtractNewRow = (e, removedIndex) => {
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedClassRow(values);
      let totalWeightLBS = parseInt(newAddedClassRow[removedIndex].WeightLBS);
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalDimensionsWeightSum) - 0;
      } else {
        minus = parseInt(totalDimensionsWeightSum) - totalWeightLBS;
      }
      setTotalClassWeightSum(minus);
      handlePushAddedCommodityInfo(removedIndex, 'totalWeightMinus');

      // setTotalDimensionsWeightSum(totalDimensionsWeightSum - totalWeightLBS);
      let TotalWeightSum = totalDimensionsWeightSum - totalWeightLBS;
    } else {
      const values = [...newAddedDimensionsRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedDimensionsRow(values);
      let totalWeightLBS = parseInt(
        newAddedDimensionsRow[removedIndex].WeightLBS
      );
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalDimensionsWeightSum) - 0;
      } else {
        minus = parseInt(totalDimensionsWeightSum) - totalWeightLBS;
      }
      // totalWeightLBS = totalLBSWeightDimensions - totalLBSWeightDimensions;
      // minus = totalDimensionsWeightSum - totalWeightLBS;
      setTotalDimensionsWeightSum(minus);
      handlePushAddedCommodityInfo(removedIndex, 'totalWeightMinus');

      setTotalLBSWeightDimensions(totalWeightLBS);
    }
  };
  useEffect(() => {
    handleCheckAddedRow();
  }, [newAddedClassRow]);

  const switchStyle = {
    borderRadius: 2,
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#d81b60'
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#d81b60'
    },
    '&:hover .MuiSwitch-switchBase': {
      // color: 'brown'
    }
  };

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }
  const [shipFromOpen, setShipFromOpen] = useState(false);
  // const [open, setOpen] = useState(false);
  const [shipToOpen, setShipToOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(
    paymentTypeValue === 'Outbound Prepaid' || paymentTypeValue === 'Sender'
      ? shipLocationValue
      : ''
  );
  const [shipFromFinalValue, setShipFromFinalValue] = useState(
    !ShipFrom.includes('undefined') ? ShipFrom : ''
  );
  const [shipToFinalValue, setShipToFinalValue] = useState(
    ShipTo.includes('undefined') ? '' : ShipTo
  );
  const [shipFromErr, setShipFromErr] = useState(false);
  const [shipToFinalAddress, setShipToFinalAddress] = useState(
    paymentTypeValue === 'Inbound Collect' || paymentTypeValue === 'Receiver'
      ? shipLocationValue
      : ''
  );
  const [shipToErr, setShipToErr] = useState(false);

  const ShipFromloading = shipFromOpen && options.length === 0;
  const ShipToLoading = shipToOpen && options.length === 0;
  const [locationKeys, setLocationKeys] = useState([]);
  // const history = useHistory()

  const onShipFromChange = (event, values) => {
    var filtered = [];
    if (values !== null) {
      filtered = shipCity.filter(option =>
        option?.toLowerCase().includes(values?.toLowerCase())
      );
    }
    setShipFromHoveredValue(filtered[0]);
    let FinalObj = {};
    if (values !== null) {
      setShipFromFinalValue(values);
      const tempData = values.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipFromErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else if (tempData.length === 5) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      }

      setShipFromFinalAddress(FinalObj);
    } else {
      // setShipFromFinalAddress('');
      // setShipFromFinalValue('');
      // setShipToFinalValue('');
    }
  };

  const onShipToChange = (event, values) => {
    var filtered = [];
    if (values !== null) {
      filtered = shipCity.filter(option =>
        option?.toLowerCase().includes(values?.toLowerCase())
      );
    }
    setShipToHoveredValue(filtered[0]);
    let FinalObj2 = {};
    if (values !== null) {
      setShipFromErr(false);
      setShipToFinalValue(values);
      const tempData = values.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipToErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj2 = {
          City,
          State,
          ZipCode
        };
        // setShipToFinalAddress(FinalObj2);
      } else if (tempData.length === 5) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        FinalObj2 = {
          City,
          State,
          ZipCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj2 = {
          City,
          State,
          ZipCode
        };

        // setShipToFinalAddress(FinalObj2);
      }
      setShipToFinalAddress(FinalObj2);
    } else {
      // setShipToFinalAddress('');
      // setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 300
  });

  const validateAutocompletes = (obj, deepClone) => {
    const errors = {};
    // if (!shipTypeValue) {
    //   errors['shipTypeErr'] = 'Please select ship type';
    // }
    //  else if (!paymentTypeValue) {
    //   errors['paymentTypeErr'] = 'Please select payment type';
    // }
    if (!shipLocationValue && paymentTypeValue !== 'Third Party') {
      errors['shipTypeLocation'] = 'Please select ship location';
      // if (selectLocations.current) {
      selectLocations.current.focus();
      // }
    } else if (
      !shipFromFinalAddress &&
      (paymentTypeValue !== 'Outbound Prepaid' || paymentTypeValue === 'Sender')
    ) {
      errors['shipFromErr'] = 'Please select ship from';
      selectShipFrom.current.focus();
    } else if (
      !shipToFinalAddress &&
      (paymentTypeValue !== 'Inbound Collect' ||
        paymentTypeValue === 'Receiver')
    ) {
      errors['shipToErr'] = 'Please select ship to';
      selectShipTo.current.focus();
    }
    // if (!newAddedClassRow[0].WeightLBS) {
    //   errors['weightLBS'] = 'Please enter weight';
    // }
    else if (!newAddedDimensionsRow[0].WeightLBS) {
      errors['newAddedDimensionsRow'] = 'Please enter weight';
      enterWeight?.current?.focus();
    } else if (!newAddedDimensionsRow[0].NmbOfUnits) {
      errors['numberOfUnits'] = 'enter number of units';
      // enterWeight.current.focus();
    } else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Length) {
      errors['PlengthError'] = 'enter length';
    } else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Width) {
      errors['PwidthError'] = 'enter width';
    } else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Height) {
      errors['PheightError'] = 'enter height';
    }

    // if (ShipFrom) {
    //   const updatedErrors = { ...validationErrors, shipToErr: null };
    //   setValidationErrors(updatedErrors);
    // }
    // add more validations for other Autocomplete components
    setValidationErrors(errors);
    if (
      shipTypeValue === 'LTL' &&
      paymentTypeValue &&
      shipFromFinalAddress &&
      shipToFinalAddress &&
      newAddedDimensionsRow[0].WeightLBS &&
      newAddedDimensionsRow[0].NmbOfUnits
    ) {
      const CopyNewAddedClassRow = [...newAddedClassRow];

      dispatch(setQuickQuoteRateValues(obj));
      dispatch(setQuickQuotePickUpCheckedRedux(pickUpChecked));
      dispatch(setQuickQuoteDeliveryCheckedRedux(deliveryChecked));
      dispatch(setClassDimensionsButton(alignment));
      dispatch(setClassRows(newAddedClassRow));
      dispatch(setDimensionRows(newAddedDimensionsRow));
      dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(totalDimensionsWeightSum));
      // console.log(obj, 'objobj');
      history.push('/get-rates', {
        state: obj,
        deepClone,
        totalLBSWeight: totalDimensionsWeightSum,
        quote: 'quick-quote',
        locationId: shipLocationID,
        shipTypeValue: shipTypeValue
        // alignment === 'Dimensions'
        //   ? totalDimensionsWeightSum
        //   : totalClassWeightSum,
      });
    } else if (
      paymentTypeValue &&
      shipFromFinalAddress &&
      shipToFinalAddress &&
      newAddedDimensionsRow[0].WeightLBS &&
      newAddedDimensionsRow[0].NmbOfUnits &&
      newAddedDimensionsRow[0].Length &&
      newAddedDimensionsRow[0].Width &&
      newAddedDimensionsRow[0].Height
    ) {
      const CopyNewAddedClassRow = [...newAddedClassRow];

      dispatch(setQuickQuoteRateValues(obj));
      dispatch(setQuickQuotePickUpCheckedRedux(pickUpChecked));
      dispatch(setQuickQuoteDeliveryCheckedRedux(deliveryChecked));
      dispatch(setClassDimensionsButton(alignment));
      dispatch(setClassRows(newAddedClassRow));
      dispatch(setDimensionRows(newAddedDimensionsRow));
      dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(totalDimensionsWeightSum));
      // console.log(obj, 'objobj');
      history.push('/get-rates', {
        state: obj,
        deepClone,
        totalLBSWeight: totalDimensionsWeightSum,
        quote: 'quick-quote',
        locationId: shipLocationID,
        shipTypeValue: shipTypeValue
        // alignment === 'Dimensions'
        //   ? totalDimensionsWeightSum
        //   : totalClassWeightSum,
      });
    }
  };

  const handleChangePaymentType = (event, newValue) => {
    var filtered = [];
    if (!Array.isArray(newValue) && newValue !== null) {
      filtered =
        shipTypeValue === 'LTL'
          ? LTLPaymentTypeArr.filter(option =>
              option.toLowerCase().includes(newValue?.toLowerCase())
            )
          : parcelPaymentTypeArr.filter(option =>
              option.toLowerCase().includes(newValue?.toLowerCase())
            );
    }
    setPaymentTypeHoveredValue(filtered[0]);
    if (newValue === null) {
      setPaymentTypeValue(LTLPaymentTypeArr[0]);
    }
    if (newValue === 'Third Party') {
      setPaymentTypeInputChange(true);
      setShipFromFinalAddress('');
      setShipToFinalAddress('');
      setShipFromFinalValue('');
      setShipToFinalValue('');
      setPaymentTypeValue(newValue);
    } else if (newValue === 'Outbound Prepaid' || newValue === 'Sender') {
      setPaymentTypeInputChange(true);
      setShipFromFinalAddress(shipLocationValue);
      setPaymentTypeValue(newValue);
      // setShipToFinalAddress('');
      // setShipToFinalValue('');
    } else if (newValue === 'Inbound Collect' || newValue === 'Receiver') {
      setPaymentTypeInputChange(true);
      setShipToFinalAddress(shipLocationValue);
      setPaymentTypeValue(newValue);
      setShipFromFinalAddress('');
      setShipFromFinalValue('');
    }
  };

  const handleShipLocationChange = (event, newValue) => {
    var filtered = [];
    if (newValue !== null && !newValue.location) {
      filtered = shipLocation.filter(option =>
        option.location?.toLowerCase().includes(newValue?.toLowerCase())
      );
      setLocationInputChange(true);
    }
    setLocationHoveredValue(filtered[0]);
    if (newValue?.location && newValue?.id !== shipLocationID) {
      const SplittedLocation = newValue.location.split(': ');
      setShipLocationValue(SplittedLocation[1]);
      setShipLocationID(newValue?.id);
      // handleLocationIdChange(newValue?.id)
      if (
        paymentTypeValue === 'Outbound Prepaid' ||
        paymentTypeValue === 'Sender'
      ) {
        setShipFromFinalAddress(SplittedLocation[1]);
      }
      if (
        paymentTypeValue === 'Inbound Collect' ||
        paymentTypeValue === 'Receiver'
      ) {
        setShipToFinalAddress(SplittedLocation[1]);
      }
      // paymentTypeValue === 'Outbound Prepaid' ?
      //   setShipFromFinalAddress(SplittedLocation[1])
      //   : setShipToFinalAddress(SplittedLocation[1]);
      setValidationErrors({
        ...validationErrors,
        shipTypeLocation: null
      });
    } else {
      setLocationInputChange(true);
    }
  };
  const handleParcelFlow = () => {
    // handleDimensionsInputChange('UnitType', 0, 'Box');
    handleChangePaymentType('event', 'Sender');
    setTotalDimensionsWeightSum('');
    setNewAddedDimensionsRow([
      {
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[2],
        NmbOfUnits: '1',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      }
    ]);
    const updatedErrors = {
      ...validationErrors,
      newAddedDimensionsRow: null
    };
    setTimeout(() => {
      setValidationErrors(updatedErrors);
    }, 50);
  };

  const handleLTLFlow = () => {
    // handleDimensionsInputChange('UnitType', 0, 'Pallet');
    // if (shipLocationValue) {
    setTotalDimensionsWeightSum('');
    handleChangePaymentType('event', 'Outbound Prepaid');
    setNewAddedDimensionsRow([
      {
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[0],
        NmbOfUnits: '1',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      }
    ]);
    const updatedErrors = {
      ...validationErrors,
      newAddedDimensionsRow: null
    };
    setTimeout(() => {
      setValidationErrors(updatedErrors);
    }, 50);
    // }
  };

  // const classes = useStyles();
  return (
    <div
      style={
        layoutMode === 'dark'
          ? {
              backgroundColor: '#303841',
              display: 'flex',
              justifyContent: 'center',
              padding: '10 0 10 0'
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              padding: '10 0 10 0'
            }
      }
    >
      <MetaTags>
        {/* <title>TMS | Quick Quote</title> */}
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Quick Quote | Shiptechpro'
            : 'Quick Quote | TMS'}
        </title>
      </MetaTags>
      <div>
        <Grid container spacing={0} justifyContent='center'>
          <Grid
            item
            xs={11}
            sm={8}
            md={10}
            lg={11}
            xl={11}
            justifyContent='center'
          >
            <Paper style={{ borderRadius: 5 }}>
              <Card
                sx={{
                  minWidth: 275
                }}
              >
                <CardContent
                  sx={{
                    height: '35vw',
                    overflowY: 'scroll',
                    position: 'relative'
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Autocomplete
                        fullWidth
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option}
                          </Box>
                        )}
                        size='small'
                        // disabled={
                        //   base_url === 'https://api.tms.dynamicwerx.com/'
                        //     ? false
                        //     : true
                        // }
                        options={ShipTypeArr}
                        value={shipTypeValue}
                        onChange={(event, newValue) => {
                          newValue === null
                            ? setShipTypeValue(ShipTypeArr[0])
                            : setShipTypeValue(newValue);
                          newValue === 'LTL'
                            ? handleLTLFlow()
                            : handleParcelFlow();
                          setValidationErrors({
                            ...validationErrors,
                            shipTypeValue: null
                          });
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(validationErrors['shipTypeErr'])}
                            helperText={validationErrors['shipTypeErr']}
                            // sx={{ width: 290 }}
                            placeholder='Ship type'
                            variant='standard'
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <MergeTypeIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Autocomplete
                        fullWidth
                        onHighlightChange={(event, option) => {
                          !locationInputChange &&
                            setLocationHoveredValue(option);
                        }}
                        // onBlur={() => {
                        //   setLocationInputChange(false);

                        //   if (locationHoveredValue) {
                        //     handleShipLocationChange(null, locationHoveredValue);
                        //   }
                        // }}
                        renderOption={(props, option, state) => (
                          <Box
                            key={state.index}
                            style={{ fontSize: 13 }}
                            {...props}
                          >
                            <b>{option?.location.split(': ')[0] + ': '}</b>{' '}
                            &nbsp;
                            {option?.location.split(': ')[1]}
                          </Box>
                        )}
                        filterOptions={(options, state) =>
                          options.filter(option =>
                            option?.location
                              .split(': ')[1]
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          )
                        }
                        openOnFocus
                        size='small'
                        value={shipLocationValue}
                        options={shipLocation}
                        getOptionLabel={option => {
                          if (shipLocationValue === option) {
                            return option;
                          } else {
                            return shipLocationValue;
                          }
                        }}
                        onChange={(event, newValue) => {
                          setLocationInputChange(false);
                          handleShipLocationChange(event, newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          handleShipLocationChange(event, newValue);
                        }}
                        loading={shipLocationLoader}
                        // sx={{ marginLeft: 1 }}
                        renderInput={params => (
                          <TextField
                            onKeyDown={e => {
                              if (e.key === 'Tab' && locationHoveredValue) {
                                handleShipLocationChange(
                                  e,
                                  locationHoveredValue
                                );
                              }
                            }}
                            inputRef={selectLocations}
                            error={Boolean(
                              validationErrors['shipTypeLocation']
                            )}
                            helperText={validationErrors['shipTypeLocation']}
                            // sx={{ width: 290 }}
                            placeholder='Select Locations'
                            variant='standard'
                            {...params}
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <LocationIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Autocomplete
                        fullWidth
                        // onHighlightChange={(event, option) => {
                        //   !paymentTypeInputChange &&
                        //     setPaymentTypeHoveredValue(option);
                        // }}
                        onBlur={() => {
                          setPaymentTypeInputChange(false);
                          if (paymentTypeHoveredValue) {
                            handleChangePaymentType(
                              null,
                              paymentTypeHoveredValue
                            );
                          }
                        }}
                        openOnFocus
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option}
                          </Box>
                        )}
                        size='small'
                        options={
                          shipTypeValue === 'LTL'
                            ? LTLPaymentTypeArr
                            : parcelPaymentTypeArr
                        }
                        disabled={!shipLocationValue ? true : false}
                        value={
                          // SelectedRates?.paymentType
                          //   ? SelectedRates?.paymentType
                          // :
                          paymentTypeValue
                        }
                        onChange={(event, newValue) => {
                          setPaymentTypeInputChange(false);
                          handleChangePaymentType(event, newValue);
                        }}
                        onInputChange={(event, newValue) =>
                          handleChangePaymentType(event, newValue)
                        }
                        // sx={{ marginLeft: 1 }}
                        renderInput={params => (
                          <TextField
                            onKeyDown={e => {
                              if (e.key === 'Tab' && paymentTypeHoveredValue) {
                                handleChangePaymentType(
                                  e,
                                  paymentTypeHoveredValue
                                );
                              }
                            }}
                            error={Boolean(validationErrors['paymentTypeErr'])}
                            helperText={validationErrors['paymentTypeErr']}
                            // sx={{ width: 290 }}
                            placeholder='Payment Type'
                            variant='standard'
                            {...params}
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <PaymentIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ mt: 1 }} container spacing={0}>
                    <Label
                      style={{
                        margin: '8px 0px 1px 0px',
                        fontWeight: 700,
                        fontSize: 12
                      }}
                    >
                      Shipment Info
                    </Label>
                    <Grid sx={{ mt: 0 }} container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={shipTypeValue === 'Parcel' ? 4 : 3}
                        lg={shipTypeValue === 'Parcel' ? 4 : 3}
                        xl={shipTypeValue === 'Parcel' ? 4 : 3}
                      >
                        <Autocomplete
                          fullWidth
                          onHighlightChange={(event, option) => {
                            !shipFromHoveredValue &&
                              setShipFromHoveredValue(option);
                          }}
                          onBlur={() => {
                            if (shipFromHoveredValue) {
                              onShipFromChange(null, shipFromHoveredValue);
                            }
                          }}
                          renderOption={(props, option, state) => (
                            <Box
                              style={{ fontSize: 13 }}
                              {...props}
                              key={state.index}
                              {...props}
                            >
                              {option}
                            </Box>
                          )}
                          disabled={
                            paymentTypeValue === 'Outbound Prepaid' ||
                            paymentTypeValue === 'Sender'
                              ? true
                              : false
                          }
                          filterOptions={filterOptions}
                          openOnFocus
                          size='small'
                          // sx={{ width: 290 }}
                          options={shipCity}
                          value={
                            paymentTypeValue === 'Outbound Prepaid' ||
                            paymentTypeValue === 'Sender'
                              ? shipLocationValue
                              : shipFromFinalValue
                          }
                          onChange={(event, newValue) =>
                            onShipFromChange(event, newValue)
                          }
                          onInputChange={(event, newValue) =>
                            onShipFromChange(event, newValue)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              onKeyDown={e => {
                                if (e.key === 'Tab' && shipFromHoveredValue) {
                                  onShipFromChange(e, shipFromHoveredValue);
                                }
                              }}
                              inputRef={selectShipFrom}
                              error={Boolean(validationErrors['shipFromErr'])}
                              helperText={validationErrors['shipFromErr']}
                              label='Ship From'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={shipTypeValue === 'Parcel' ? 4 : 3}
                        lg={shipTypeValue === 'Parcel' ? 4 : 3}
                        xl={shipTypeValue === 'Parcel' ? 4 : 3}
                      >
                        <Autocomplete
                          fullWidth
                          onHighlightChange={(event, option) => {
                            !shipToHoveredValue &&
                              setShipToHoveredValue(option);
                          }}
                          onChange={(event, newValue) =>
                            onShipToChange(event, newValue)
                          }
                          onInputChange={(event, newValue) =>
                            onShipToChange(event, newValue)
                          }
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option}
                            </Box>
                          )}
                          filterOptions={filterOptions}
                          disabled={
                            paymentTypeValue === 'Inbound Collect' ||
                            paymentTypeValue === 'Receiver'
                              ? true
                              : false
                          }
                          size='small'
                          // sx={{ width: 290 }}
                          options={shipCity}
                          value={
                            paymentTypeValue === 'Inbound Collect' ||
                            paymentTypeValue === 'Receiver'
                              ? shipLocationValue
                              : shipToFinalValue
                          }
                          openOnFocus
                          // onChange={onShipToChange}
                          renderInput={params => (
                            <TextField
                              onKeyDown={e => {
                                if (e.key === 'Tab' && shipToHoveredValue) {
                                  // e.preventDefault(); // prevent the default Tab behavior
                                  onShipToChange(e, shipToHoveredValue);
                                }
                              }}
                              inputRef={selectShipTo}
                              // required={true}
                              {...params}
                              error={Boolean(validationErrors['shipToErr'])}
                              helperText={validationErrors['shipToErr']}
                              label='Ship To'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={shipTypeValue === 'Parcel' ? 4 : 3}
                        lg={shipTypeValue === 'Parcel' ? 4 : 3}
                        xl={shipTypeValue === 'Parcel' ? 4 : 3}
                      >
                        <TextField
                          fullWidth
                          onChange={e => setShipmentDate(e.target.value)}
                          size='small'
                          // sx={
                          //   shipTypeValue === 'LTL'
                          //     ? { width: 170, marginRight: 1 }
                          //     : { width: 290 }
                          // }
                          id='outlined-password-input'
                          defaultValue={shipmentDate}
                          type='date'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            min: today,
                            style: {
                              fontSize: 12
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        {shipTypeValue === 'LTL' && (
                          <Chip
                            variant='outlined'
                            sx={{ width: '100%', borderRadius: '0.5' }}
                            fullWidth
                            label={
                              <FormControlLabel
                                fullWidth
                                control={
                                  <Switch
                                    fullWidth
                                    checked={hazmatSwitch}
                                    value={hazmatSwitch}
                                    sx={switchStyle}
                                    onChange={() =>
                                      handlePickUpServicesChange('hazmat')
                                    }
                                    name='hazmat'
                                  />
                                }
                                label={
                                  <Typography
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 700
                                    }}
                                  >
                                    Hazmat
                                  </Typography>
                                }
                              />
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {shipTypeValue === 'Tanker' ? (
                    <div>
                      <Label
                        style={{
                          margin: '20px 0px 20px 0px',
                          fontWeight: 700,
                          fontSize: 12
                        }}
                      >
                        Tanker / Equipment Requirements
                      </Label>
                    </div>
                  ) : (
                    <div>
                      <Label
                        style={{
                          margin: '17px 0px 1px 0px',
                          fontWeight: 700,
                          fontSize: 12
                        }}
                      >
                        Commodity Info
                      </Label>
                    </div>
                  )}

                  <>
                    {shipTypeValue === 'Tanker' ? (
                      <div style={{ height: '20vh' }}>
                        <ReactQuill
                          style={{ height: '15vh' }}
                          // value={pickupInstContent}
                          // onChange={handlePickupInstEditorChange}
                        />
                      </div>
                    ) : (
                      newAddedDimensionsRow.map((items, index, arr) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label
                              style={{
                                margin: '10px 0px 6px 28px',
                                fontWeight: 700,
                                fontSize: 12,
                                whiteSpace: 'noWrap'
                              }}
                            >
                              Weight (lbs)
                            </Label>

                            <Grid
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              {index + 1 === arr.length && arr.length > 1 ? (
                                <RemoveCircleIcon
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={e =>
                                    handleSubtractNewRow('dimensions', index)
                                  }
                                />
                              ) : (
                                <AddCircleIcon
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={e => handleAddNewRow('dimensions')}
                                />
                              )}

                              <TextField
                                fullWidth
                                className={classes.inputRoot}
                                inputRef={enterWeight}
                                error={Boolean(
                                  validationErrors['newAddedDimensionsRow']
                                )}
                                helperText={
                                  validationErrors['newAddedDimensionsRow']
                                }
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 0 0'
                                  }
                                }}
                                value={
                                  items.WeightLBS === 0 ? '' : items.WeightLBS
                                }
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'WeightLBS',
                                    index,
                                    e
                                  )
                                }
                                size='small'
                                sx={{ ml: 0.5 }}
                                // sx={
                                //   shipTypeValue === 'LTL'
                                //     ? { width: 100 }
                                //     : { width: 190 }
                                // }
                                id='outlined-password-input'
                                type='number'
                                autoComplete='current-password'
                                InputLabelProps={{
                                  style: { fontSize: 12 }
                                }}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  style: {
                                    height: 32,
                                    fontSize: 12
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>

                          {shipTypeValue === 'LTL' && (
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Label
                                style={{
                                  margin: '10px 0px 6px 0px',
                                  fontWeight: 700,
                                  fontSize: 12,
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Freight Class
                              </Label>
                              <Autocomplete
                                fullWidth
                                // onHighlightChange={(event, option) => {
                                //   setFreightClassHoveredValue(option);
                                // }}
                                onBlur={() => {
                                  if (freightClassHoveredValue) {
                                    handleDimensionsInputChange(
                                      'Class',
                                      index,
                                      freightClassHoveredValue
                                    );
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <Box style={{ fontSize: 13 }} {...props}>
                                    {option}
                                  </Box>
                                )}
                                name='Class'
                                size='small'
                                options={ClassArray}
                                // defaultValue={'50'}
                                value={items.Class}
                                onChange={(event, newValue) =>
                                  handleDimensionsInputChange(
                                    'Class',
                                    index,
                                    newValue
                                  )
                                }
                                onInputChange={(event, newValue) =>
                                  handleDimensionsInputChange(
                                    'Class',
                                    index,
                                    newValue
                                  )
                                }
                                // sx={{ marginLeft: 1 }}
                                renderInput={params => (
                                  <TextField
                                    onKeyDown={e => {
                                      if (
                                        e.key === 'Tab' &&
                                        freightClassHoveredValue
                                      ) {
                                        // e.preventDefault(); // prevent the default Tab behavior
                                        handleDimensionsInputChange(
                                          'Class',
                                          e,
                                          freightClassHoveredValue
                                        );
                                      }
                                    }}
                                    // sx={{ width: 90 }}
                                    // placeholder='Payment Type'
                                    variant='outlined'
                                    {...params}
                                    InputProps={{
                                      style: { fontSize: 12, height: 32 },
                                      ...params.InputProps
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Unit Type
                            </Label>
                            <Autocomplete
                              // onHighlightChange={(event, option) => {
                              //   setUnitTypeHoveredValue(option);
                              // }}
                              fullWidth
                              onBlur={() => {
                                if (unitTypeHoveredValue) {
                                  handleDimensionsInputChange(
                                    'UnitType',
                                    index,
                                    unitTypeHoveredValue
                                  );
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option}
                                </Box>
                              )}
                              // sx={{ m: 1 }}
                              size='small'
                              options={
                                shipTypeValue === 'LTL'
                                  ? LTLUnitTypeArr
                                  : ParcelUnitTypeArr
                              }
                              value={
                                // shipTypeValue === 'Parcel'
                                //   ? ParcelUnitTypeArr[0]
                                //   :
                                items.UnitTypeValue
                              }
                              onChange={(event, newValue) => {
                                handleDimensionsInputChange(
                                  'UnitType',
                                  index,
                                  newValue
                                );
                              }}
                              onInputChange={(event, newValue) => {
                                handleDimensionsInputChange(
                                  'UnitType',
                                  index,
                                  newValue
                                );
                              }}
                              // sx={{ marginLeft: 1 }}
                              renderInput={params => (
                                <TextField
                                  fullWidth
                                  onKeyDown={e => {
                                    if (
                                      e.key === 'Tab' &&
                                      unitTypeHoveredValue
                                    ) {
                                      // e.preventDefault(); // prevent the default Tab behavior
                                      handleDimensionsInputChange(
                                        'UnitType',
                                        e,
                                        unitTypeHoveredValue
                                      );
                                    }
                                  }}
                                  // type='number'
                                  // sx={{ width: 120 }}
                                  // sx={
                                  //   shipTypeValue === 'LTL'
                                  //     ? { width: 120 }
                                  //     : { width: 190 }
                                  // }
                                  variant='outlined'
                                  {...params}
                                  InputProps={{
                                    style: { fontSize: 12, height: 32 },
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Label
                              style={
                                shipTypeValue === 'Parcel'
                                  ? {
                                      margin: '10px 0px 6px 0px',
                                      fontWeight: 700,
                                      fontSize: 12
                                    }
                                  : {
                                      margin: '6px 0px 6px 0px',
                                      fontWeight: 700,
                                      fontSize: 12
                                    }
                              }
                            >
                              # of Units
                            </Label>
                            <Grid
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <TextField
                                fullWidth
                                error={Boolean(
                                  validationErrors['numberOfUnits']
                                )}
                                helperText={validationErrors['numberOfUnits']}
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 -15 0'
                                  }
                                }}
                                value={items.NmbOfUnits}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'NmbOfUnits',
                                    index,
                                    e
                                  )
                                }
                                size='small'
                                // sx={
                                //   shipTypeValue === 'LTL'
                                //     ? { width: 90 }
                                //     : { width: 190 }
                                // }
                                id='outlined-password-input'
                                type='number'
                                autoComplete='current-password'
                                // InputLabelProps={{ style: { fontSize: 12 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  },
                                  min: 0
                                }}
                              />
                              {shipTypeValue === 'LTL' && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        sx={{ ml: 2 }}
                                        checked={items.StackAble}
                                        value={items.StackAble}
                                        onChange={(e, c) =>
                                          // handlePickUpServicesChange('Stackable')
                                          handleDimensionsInputChange(
                                            'Stackable',
                                            index,
                                            c
                                          )
                                        }
                                        color='primary'
                                        name='hazmat'
                                      />
                                    }
                                  />
                                  <Typography
                                    style={{
                                      margin: '0 0 0 -20',
                                      fontSize: 12,
                                      // whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Stackable
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={shipTypeValue === 'LTL' ? 3 : 5}
                            xl={shipTypeValue === 'LTL' ? 3 : 5}
                          >
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Dimensions
                            </Label>
                            <Grid sx={{ display: 'flex' }}>
                              <TextField
                                fullWidth
                                error={Boolean(
                                  validationErrors['PlengthError']
                                )}
                                helperText={validationErrors['PlengthError']}
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 -10 0'
                                  }
                                }}
                                value={items.Length}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'Length',
                                    index,
                                    e
                                  )
                                }
                                type='number'
                                onKeyPress={handleKeyPress}
                                // className='inputRounded_Length'
                                size='small'
                                // sx={{ width: 95 }}
                                id='outlined-password-input'
                                label='Length (inches)'
                                className={classes.input}
                                // autoComplete='current-password'
                                // value={'L'}
                                InputLabelProps={{
                                  style: { fontSize: 11 }
                                }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                              <TextField
                                fullWidth
                                error={Boolean(validationErrors['PwidthError'])}
                                helperText={validationErrors['PwidthError']}
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 -10 0'
                                  }
                                }}
                                value={items.Width}
                                onChange={e =>
                                  handleDimensionsInputChange('Width', index, e)
                                }
                                type='number'
                                onKeyPress={handleKeyPress}
                                // className='inputRounded_Width'
                                className={classes.input}
                                size='small'
                                // sx={{ width: 95 }}
                                id='outlined-password-input'
                                label='Width (inches)'
                                autoComplete='current-password'
                                // value={'W'}
                                InputLabelProps={{
                                  style: { fontSize: 11 }
                                }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                              <TextField
                                fullWidth
                                error={Boolean(
                                  validationErrors['PheightError']
                                )}
                                helperText={validationErrors['PheightError']}
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 -10 0'
                                  }
                                }}
                                value={items.Height}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'Height',
                                    index,
                                    e
                                  )
                                }
                                type='number'
                                onKeyPress={handleKeyPress}
                                className={classes.input}
                                // className='inputRounded_Height'
                                size='small'
                                // sx={{ width: 95 }}
                                id='outlined-password-input'
                                label='Height (inches)'
                                autoComplete='current-password'
                                // value={'H'}
                                InputLabelProps={{
                                  style: { fontSize: 11 }
                                }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                            </Grid>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#1976d2',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: '8 0 0 0'
                              }}
                            >
                              <u>
                                {index + 1 === arr.length && (
                                  <span>
                                    Total Shipment Weight{' '}
                                    {totalDimensionsWeightSum > 0 &&
                                      totalDimensionsWeightSum}{' '}
                                    (lbs)
                                  </span>
                                )}
                              </u>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    )}
                  </>
                  {/* )} */}
                  {shipTypeValue === 'LTL' && (
                    <Row>
                      <Col lg={3}>
                        <div
                          style={{ margin: '-15 90 0 0' }}
                          className={classes.switchRoot}
                        >
                          <Typography
                            onClick={e => handlePickupServices(e)}
                            style={{
                              margin: '0 10 0 4',
                              fontSize: 12,
                              whiteSpace: 'nowrap',
                              fontWeight: 700
                            }}
                          >
                            Pickup Services
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={e => handlePickupServices(e)}
                                checked={pickUpChecked}
                                color='primary'
                                name='Pickup Services'
                              />
                            }
                          />
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div
                          style={{ margin: '-10 90 0 0' }}
                          className={classes.switchRoot}
                        >
                          <Typography
                            onClick={() => handleDeliveryServices()}
                            style={{
                              margin: '0 10 0 4',
                              fontSize: 12,
                              whiteSpace: 'nowrap',
                              fontWeight: 700
                            }}
                          >
                            Delivery Services
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={() => handleDeliveryServices()}
                                checked={deliveryChecked}
                                color='primary'
                                name='Delivery Services'
                              />
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  {(pickUpChecked || deliveryChecked) &&
                    shipTypeValue === 'LTL' && (
                      <>
                        <Row>
                          {
                            <>
                              {pickUpChecked && (
                                <Col lg={3}>
                                  <FormControl
                                    sx={{ m: 0, minWidth: 230 }}
                                    size='large'
                                  >
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Inside Pickup'
                                              )
                                            }
                                            checked={insidePickupSwitch}
                                            color='primary'
                                            name='Pickup Services'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Inside Pickup'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Inside Pickup
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Inside Lift Gate Services'
                                              )
                                            }
                                            checked={liftgatePickupSwitch}
                                            color='primary'
                                            name='Inside Lift Gate Services'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Inside Lift Gate Services'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Lift Gate Services
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Residential Pickup'
                                              )
                                            }
                                            checked={residentialPickupSwitch}
                                            color='primary'
                                            name='Residential Pickup'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Residential Pickup'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Residential Pickup
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Secure Access'
                                              )
                                            }
                                            checked={secureAccesPickupSwitch}
                                            color='primary'
                                            name='Secure Access'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Secure Access
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Limited Access'
                                              )
                                            }
                                            checked={limitedPickupAccessSwitch}
                                            color='primary'
                                            name='Limited Access'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Limited Access
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Freeze Protection'
                                              )
                                            }
                                            checked={
                                              freezeProtectionPickupSwitch
                                            }
                                            color='primary'
                                            name='Freeze Protection'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Freeze Protection'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Freeze Protection
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handlePickUpServicesChange(
                                                'Overlength'
                                              )
                                            }
                                            checked={overlengthPickupSwitch}
                                            color='primary'
                                            name='Overlength'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handlePickUpServicesChange(
                                            'Overlength'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Overlength
                                      </Typography>
                                    </div>
                                    {isOverLength && (
                                      <FormControl
                                        sx={{ minWidth: 120, mt: 1 }}
                                        size='large'
                                      >
                                        <TextField
                                          value={overLengthValue}
                                          onChange={e =>
                                            setOverLengthValue(e.target.value)
                                          }
                                          size='small'
                                          label='Overlength Value (Inch)'
                                          placeholder='Overlength Value (Inch)'
                                          type='number'
                                          sx={{ width: 170 }}
                                          InputLabelProps={{
                                            style: { fontSize: 14 }
                                          }}
                                          inputProps={
                                            ({ min: 0 },
                                            {
                                              style: {
                                                height: 15,
                                                fontSize: 12
                                              }
                                            })
                                          }
                                        />
                                      </FormControl>
                                    )}
                                  </FormControl>
                                </Col>
                              )}
                              {deliveryChecked && (
                                <Col lg={3}>
                                  <FormControl
                                    sx={{
                                      m: !pickUpChecked ? '0 330' : '0 0 0 0',
                                      minWidth: 230
                                    }}
                                    size='large'
                                  >
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Inside Delivery'
                                              )
                                            }
                                            checked={insideDeliverySwitch}
                                            color='primary'
                                            name='Delivery Services'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Inside Delivery'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Inside Delivery
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Liftgate Delivery'
                                              )
                                            }
                                            checked={liftgateDeliverySwitch}
                                            color='primary'
                                            name='Liftgate Delivery'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Liftgate Delivery'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Lift Gate Services
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Residential Delivery'
                                              )
                                            }
                                            checked={residentialDeliverySwitch}
                                            color='primary'
                                            name='Residential Delivery'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Residential Delivery'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Residential Delivery
                                      </Typography>
                                    </div>

                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Secure Access'
                                              )
                                            }
                                            checked={secureAccesDeliverySwitch}
                                            color='primary'
                                            name='Secure Access'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Secure Access
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Limited Access'
                                              )
                                            }
                                            checked={limitedDeliverySwitch}
                                            color='primary'
                                            name='Limited Access'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Limited Access
                                      </Typography>
                                    </div>

                                    <div
                                      style={{ margin: '-10 90 0 0' }}
                                      className={classes.switchRoot}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() =>
                                              handleDeliveryServicesChange(
                                                'Call Before Delivery'
                                              )
                                            }
                                            checked={
                                              callBeforeDeliveryServicesSwitch
                                            }
                                            color='primary'
                                            name='Call Before Delivery'
                                          />
                                        }
                                      />
                                      <Typography
                                        onClick={() =>
                                          handleDeliveryServicesChange(
                                            'Call Before Delivery'
                                          )
                                        }
                                        style={{
                                          margin: '0 0 0 -10',
                                          fontSize: 12,
                                          whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Call Before Delivery
                                      </Typography>
                                    </div>
                                  </FormControl>
                                </Col>
                              )}
                            </>
                          }
                        </Row>
                        <Row>
                          <Label
                            style={{
                              margin: '15px 0px 10px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Additional Fee May Apply
                          </Label>

                          <Col lg={5}>
                            <FormControl
                              sx={{ m: 0, minWidth: 200 }}
                              size='small'
                            >
                              <Label
                                style={{
                                  margin: '10px 0px 0px 0px',
                                  fontWeight: 700,
                                  fontSize: 12
                                }}
                              >
                                C.O.D Amounts($)
                              </Label>
                              <TextField
                                value={CODAmount === 0 ? '' : CODAmount}
                                onChange={e => setCODAmount(e.target.value)}
                                type='number'
                                size='small'
                                sx={{ width: 270 }}
                                id='outlined-password-input'
                                autoComplete='current-password'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  }
                                }}
                              />
                            </FormControl>
                          </Col>
                          <Col lg={5}>
                            <FormControl
                              sx={{ m: 0, minWidth: 200 }}
                              size='small'
                            >
                              <Label
                                style={{
                                  margin: '10px 0px 0px 0px',
                                  fontWeight: 700,
                                  fontSize: 12
                                }}
                              >
                                Declared Value($)
                              </Label>
                              <TextField
                                value={DeclaredValue}
                                onChange={e => setDeclaredValue(e.target.value)}
                                type='number'
                                size='small'
                                sx={{ width: 270 }}
                                id='outlined-password-input'
                                // label='Ship To'
                                autoComplete='current-password'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  }
                                }}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                      </>
                    )}
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={
                      (pickUpChecked || deliveryChecked) &&
                      shipTypeValue === 'LTL'
                        ? {
                            display: 'flex',
                            justifyContent: 'center'
                            // marginTop: '1vw'
                          }
                        : {
                            display: 'flex',
                            justifyContent: 'center'
                            // marginTop: '10vw'
                          }
                    }
                  >
                    <Button
                      type='submit'
                      // sx={{ position: 'fixed', mt: 1 }}
                      // disabled={
                      //   !shipFromFinalAddress ||
                      //   !shipToFinalAddress ||
                      //   !shipTypeValue ||
                      //   !shipLocationValue ||
                      //   !paymentTypeValue
                      //     ? true
                      //     : false
                      // }
                      onClick={e => handleGetRates(e)}
                      // variant='contained'
                      color='Blue'
                      size='small'
                    >
                      {getRateLoading ? 'Loading ...' : 'Get Rates'}
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </Paper>
          </Grid>
        </Grid>
        {/* )} */}
      </div>
    </div>
  );
}

export default QuickQuote;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  CircularProgress,
  Button
} from '@mui/material';
import './ViewShipment.css';
import {
  GetBillOfLading,
  GetProofOfDelivery,
  GetQuoteDetail,
  TrackShipmentRequest
} from '../../apis/Agency';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { ErrorToast } from '../../components/Toasts';
import { Col, Row } from 'reactstrap';
import CachedIcon from '@mui/icons-material/Cached';
import TrackShipmentDetails from '../TrackShipment/TrackShipmentDetails';
import TrackHistory from '../TrackShipment/TrackHistory';
import DownloadIcon from '@mui/icons-material/Download';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// VERTICAL

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 1, width: '115%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

// HORIZONTAL

function TabPanelX(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3, py: 1, maxWidth: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelX.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function ViewShipment(props) {
  const handleDateTimeShow = (timeStamp, type) => {
    const dateTimeString = timeStamp + 'Z';
    const dateTime = new Date(dateTimeString);
    const date = new Date(dateTimeString);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeOptions = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(dateTime);
    const dateOptions = {
      // weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };

    const formattedDate = date.toLocaleDateString(undefined, dateOptions);

    if (type === 'time') {
      return timeOptions;
    }
    if (type === 'date') {
      return formattedDate;
    }
  };
  const shipmentStatus = props?.rowData?.shipmentStatus;
  const [trackShipmentData, setTrackShipmentData] = useState();

  const handleSuccessTrackShip = apiRes => {
    setLoader(false);
    setTrackShipmentData(apiRes);
    // history.push({
    //   pathname: '/track-shipment',
    //   state: { trackShipmentData: apiRes }
    // });
  };
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [blobUrlBOL, setBlobUrlBOL] = useState('');
  const [blobUrlPOD, setBlobUrlPOD] = useState('');
  const [loader, setLoader] = useState(false);
  const [docLoader, setDocLoader] = useState(false);
  const [historyError, setHistoryError] = useState(false);
  const [PodDocLoader, setPodDocLoader] = useState(false);
  const [viewType, setViewType] = useState('Track');
  const handleTrackShipmentRequest = (selectedRowData, event, type) => {
    if (type === 'history') {
      setViewType(type);
      event.stopPropagation();
    } else {
      setLoader(true);
      event.stopPropagation();
      console.log(selectedRowData, 'selectedRowData');
      const obj = {
        carrierInfo: {
          id: selectedRowData?.carrierId ? selectedRowData.carrierId : 0,
          name: selectedRowData?.carrierName ? selectedRowData.carrierName : '',
          logo: ''
        },
        proNumber: selectedRowData?.proNumber,
        shipmentId: selectedRowData?.shipmentId,
        locationId: selectedRowData?.locationId,
        shipmentType: selectedRowData?.shipmentType,
        agencyId: AgencyID ? AgencyID : 0
      };

      TrackShipmentRequest(obj)
        .then(res => res.status === 200 && handleSuccessTrackShip(res.data))
        .catch(err => {
          setLoader(false);
          ErrorToast(JSON.stringify(err?.response?.data));
        });
    }
  };

  const viewBOL = (fileType, base64Data, urls, fileName) => {
    // console.log(urls[0], 'BOL');
    let mimeType;
    if (base64Data !== null) {
      switch (fileType.toLowerCase()) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'tif':
        case 'tiff':
        case 'tifv':
          mimeType = 'image/tiff';
          break;
        case '.gif':
        case 'gif':
          mimeType = 'image/gif';
          break;
        case 'jpg':
        case '.jpg':
          mimeType = 'image/jpg';
          break;
        default:
          console.error('Unsupported file type');
          return;
      }

      const linkSource = `data:${mimeType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;

      // Set the download attribute with the correct file extension
      downloadLink.download = fileName.endsWith(`.${fileType}`)
        ? fileName
        : `${fileName}.${fileType}`;

      // Trigger the download
      downloadLink.click();
    }
    if (urls !== null) {
      window.open(urls[0]);
    }

    // if (pdf.includes('tif') || pdf.includes('TIF')) {
    //   var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    //   if (base64regex.test(base64Data)) {
    //     setBlobUrlBOL(null);
    //     const linkSource = `data:image/tiff;base64,${base64Data}`;
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    //   }
    // }
    // if ((pdf.includes('pdf') || pdf.includes('PDF')) && base64Data) {
    //   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    //   const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change the MIME type according to your file type
    //   const url = URL.createObjectURL(blob);
    //   setBlobUrlBOL(url);
    // }
  };
  const viewPOD = (fileType, base64Data, urls, fileName) => {
    let mimeType;
    if (base64Data !== null) {
      switch (fileType.toLowerCase()) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'tif':
        case 'tiff':
        case 'tifv':
          mimeType = 'image/tiff';
          break;
        case '.gif':
        case 'gif':
          mimeType = 'image/gif';
          break;
        case 'jpg':
        case '.jpg':
          mimeType = 'image/jpg';
          break;
        default:
          console.error('Unsupported file type');
          return;
      }

      const linkSource = `data:${mimeType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;

      // Set the download attribute with the correct file extension
      downloadLink.download = fileName.endsWith(`.${fileType}`)
        ? fileName
        : `${fileName}.${fileType}`;

      // Trigger the download
      downloadLink.click();
    }
    if (urls !== null) {
      window.open(urls[0]);
    }
    // if (pdf.includes('tif') || pdf.includes('TIF')) {
    //   var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    //   if (base64regex.test(base64Data)) {
    //     setBlobUrlPOD(null);
    //     const linkSource = `data:image/tiff;base64,${base64Data}`;
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    //   }
    // }
    // if ((pdf.includes('pdf') || pdf.includes('PDF')) && base64Data) {
    //   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    //   const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change the MIME type according to your file type
    //   const url = URL.createObjectURL(blob);
    //   setBlobUrlPOD(url);
    // }
  };

  function handleSuccessBOL(pdf, base64Data, fileName) {
    if (pdf.includes('pdf') || pdf.includes('PDF')) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const fileBlob = new Blob([byteArray], { type: 'application/pdf' });
      saveAs(fileBlob, fileName);
    }
    if (pdf.includes('tiff') || pdf.includes('TIFF')) {
      var base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (base64regex.test(base64Data)) {
        const linkSource = `data:${pdf};base64,${base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }
  const handleProofOfDeliveryRequest = status => {
    setPodDocLoader(true);
    const obj = {
      carrier: {
        id: rowData?.carrierId ? rowData.carrierId : 0,
        name: rowData?.carrierName ? rowData.carrierName : 'string',
        logo: 'string'
      },
      proNumber: rowData?.proNumber.trim(),
      shipmentId: rowData?.shipmentId,
      locationId: rowData?.locationId,
      agencyId: AgencyID ? AgencyID : 0
    };
    GetProofOfDelivery(obj)
      .then(res => {
        res.status === 200 && setPodDocLoader(false);
        viewPOD(
          res.data.fileExtension,
          res.data.fileBase64,
          res.data.urLs,
          `Proof of Delivery (${obj.carrier.name}  ${obj.proNumber})`
        );
      })
      .catch(err => {
        setPodDocLoader(false);
        setBlobUrlPOD(null);
        ErrorToast(JSON.stringify(err?.response?.data));
      });
  };

  const handleBillOfLadingRequest = status => {
    setDocLoader(true);
    const obj = {
      carrier: {
        id: rowData?.carrierId ? rowData.carrierId : 0,
        name: rowData?.carrierName ? rowData.carrierName : 'string',
        logo: 'string'
      },
      proNumber: rowData?.proNumber.trim(),
      shipmentId: rowData?.shipmentId,
      locationId: rowData?.locationId,
      agencyId: AgencyID ? AgencyID : 0
    };

    GetBillOfLading(obj)
      .then(res => {
        res.status === 200 && setDocLoader(false);
        viewBOL(
          res.data.fileExtension,
          res.data.fileBase64,
          res.data.urLs,
          `Bill of Lading (${obj.carrier.name}  ${obj.proNumber})`
        );
      })
      .catch(err => {
        setBlobUrlBOL(null);
        setDocLoader(false);
        ErrorToast(JSON.stringify(err?.response?.data));
      });
  };

  const rowData = props?.rowData;

  // Vertical
  const [valueY, setValueY] = useState(0);
  const [shipperApiMsgs, setShipperApiMsgs] = useState('');
  const [carrierApiMsgs, setCarrierApiMsgs] = useState('');
  const [corresHistory, setCorresHistory] = useState('');
  const handleSuccessResponse = apiRes => {
    setHistoryError(false);
    const carrierData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'carrier'
    );

    setShipperApiMsgs(apiRes.data.data.details);
    setCarrierApiMsgs(carrierData);
    setDocLoader(false);
  };

  const handleGetCorrHistory = apiRes => {
    const shipperSessionId = apiRes?.quoteCarriers?.find(
      ratesArr => ratesArr.carrierResponseStatus === 'ACCEPTED'
    );
    if (rowData?.sessionId) {
      GetQuoteDetail(rowData?.sessionId)
        .then(res => res.status === 200 && handleSuccessResponse(res))
        .catch(err => {
          setDocLoader(false);
          ErrorToast(err?.message);
        });
    } else {
      // ErrorToast('No history found against this shipment!');
      setHistoryError(true);
      setDocLoader(false);
    }
  };

  const handleGetQuote = () => {
    handleGetCorrHistory();
    // GetQuote(rowData?.quoteId)
    //   .then(res => {
    //     res.status === 200 && handleGetCorrHistory(res.data.data);
    //     setDocLoader(false);
    //   })
    //   .catch(err => console.log(err, 'TL quote Err----->>>>'));
  };
  const handleChangeY = (event, newValue) => {
    setValueY(newValue);
    if (newValue === 1) {
      setDocLoader(true);
      handleGetQuote();
    }
  };

  // Horizontal
  const [valueX, setValueX] = useState(0);

  const handleChangeX = (event, newValue) => {
    setValueX(newValue);
  };

  const handleChangeIndex = index => {
    setValueX(index);
  };
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  const removeTableCss = finalRes => {
    const regexPattern = /<style[^>]*>([\s\S]*?)<\/style>/gi;

    // Remove table styles including borders
    const updatedHtml = finalRes.replace(regexPattern, (match, styles) => {
      const stylesWithoutTable = styles.replace(
        /(?:table|td|th)\s*\{[^}]*\}/gi,
        ''
      );
      return `<style>${stylesWithoutTable} table { font-size: 12px; } td { padding: 5px; }</style>`;
    });
    return updatedHtml;
  };

  const handleCorresHistory = apiRes => {
    // Regex pattern to capture and replace font-size in table styles
    const fontSizeToUpdate = removeTableCss(apiRes);

    return fontSizeToUpdate;

    // removeTableCss(updatedHtml);
  };
  return (
    <div className='d-flex ' style={{ justifyContent: 'center' }}>
      {/* <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Box
        sx={{
          // bgcolor: 'background.paper',
          // backgroundColor: 'red',
          display: 'flex',
          width: '100%',
          borderRadius: '5px',
          // marginTop: -20
          p: 3,
          height: 'auto'
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={valueY}
          onChange={handleChangeY}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            mr: 1
          }}
        >
          {(shipmentStatus === 'InTransit' ||
            shipmentStatus === 'Delivered') && (
            <Tab
              sx={{ display: 'flex', alignItems: 'flex-start' }}
              label='Shipment Info'
              {...a11yProps(0)}
            />
          )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            rowData?.shipmentType === 'TL' && (
              <Tab
                sx={{ textAlign: 'right' }}
                label='Corresponding History'
                {...a11yProps(1)}
              />
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              <Button
                size='small'
                variant='outlined'
                sx={{ mr: 1, width: 170 }}
                onClick={() => handleBillOfLadingRequest('')}
              >
                {docLoader ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    Bill of Lading
                    <DownloadIcon sx={{ fontSize: 18 }} />
                  </>
                )}
              </Button>
              /* <Tab
              onClick={() => handleBillOfLadingRequest('view')}
              label='Bill of Landing'
              {...a11yProps(1)}
            /> */
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              /* <Tab
              onClick={() => handleProofOfDeliveryRequest('view')}
              label='Proof of Delivery'
              {...a11yProps(2)}
            /> */
              <Button
                sx={{ mt: 2, mr: 1, width: 170 }}
                size='small'
                variant='outlined'
                onClick={() => handleProofOfDeliveryRequest('')}
              >
                {PodDocLoader ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    Proof of Delivery
                    <DownloadIcon sx={{ fontSize: 18 }} />
                  </>
                )}
              </Button>
            )}
          {/* <Tab label='Freight Invoice' {...a11yProps(3)} /> */}
          {/* <Tab label='Packaging Detail' {...a11yProps(4)} /> */}
          {/* {(shipmentStatus === 'InTransit' ||
            shipmentStatus === 'Delivered') && (
            <Tab label='Track Shiping' {...a11yProps(5)} />
          )} */}
        </Tabs>
        <TabPanel value={valueY} index={0}>
          <Box sx={{ bgcolor: 'background.paper', width: '90vw' }}>
            <TabPanelX value={valueX} index={0}>
              <div>
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Col lg={3} xl={3}>
                    <div className='View_Details_Headings'>
                      Origin Information
                    </div>
                  </Col>
                  <Col lg={3} xl={3}>
                    <div className='View_Details_Headings'>
                      Destination Information
                    </div>
                  </Col>
                  <Col lg={3} xl={3}>
                    <div className='View_Details_Headings'>
                      Bill To Information
                    </div>
                  </Col>
                  <Col lg={3} xl={3}>
                    <div style={{ display: 'flex' }}>
                      <div
                        className='View_Details_Headings'
                        style={{ marginLeft: -124 }}
                      >
                        Delivery Status
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr />
              <Row>
                <Col lg={3} xl={3}>
                  <div>
                    <div className='View_Details_Headings'>Origin</div>
                    <span className='View_Details'>
                      {rowData.originZipCode +
                        ', ' +
                        rowData.originCity +
                        ', ' +
                        rowData.originState}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Name</div>
                    <span className='View_Details'>
                      {rowData.originContactPerson}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Phone</div>
                    <span className='View_Details'>
                      {rowData.originContactPhone}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Email</div>
                    <span className='View_Details'>
                      {' '}
                      {rowData.originContactEmail}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Ship Date</div>
                    <span className='View_Details'>
                      {' '}
                      {handleDateTimeShow(rowData.actualPickupDate, 'date')}
                    </span>
                  </div>
                </Col>
                <Col lg={3} xl={3}>
                  <div>
                    <div className='View_Details_Headings'>Destination</div>
                    <span className='View_Details'>
                      {' '}
                      {rowData.destinationZipCode +
                        ', ' +
                        rowData.destinationCity +
                        ', ' +
                        rowData.destinationState}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Name</div>
                    <span className='View_Details'>
                      {' '}
                      {rowData.destinationContactPerson}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Phone</div>
                    <span className='View_Details'>
                      {rowData.destinationContactPhone}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Email</div>
                    <span className='View_Details'>
                      {' '}
                      {rowData.destinationContactEmail}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>
                      Carrier's Delivery Date
                    </div>
                    <span className='View_Details'>
                      {handleDateTimeShow(rowData.actualDeliveryDate, 'date')}
                    </span>
                  </div>
                </Col>
                <Col lg={2} xl={2}>
                  <div>
                    <div className='View_Details_Headings'>Bill To</div>
                    <span className='View_Details'>
                      {' '}
                      {rowData.billToZipCode +
                        ', ' +
                        rowData.billToCity +
                        ', ' +
                        rowData.billToState}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Name</div>
                    <span className='View_Details'>
                      {rowData.billToContactPerson}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Phone</div>
                    <span className='View_Details'>
                      {rowData.billToContactPhone}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Email</div>
                    <span className='View_Details'>
                      {rowData.billToContactEmail}
                    </span>
                  </div>
                </Col>
                <Col lg={3} xl={3}>
                  <Button
                    sx={{ minWidth: 130 }}
                    disabled={
                      rowData?.shipmentStatus === 'Saved' ||
                      rowData?.shipmentStatus === 'Booked' ||
                      rowData?.shipmentStatus === 'Hold' ||
                      rowData?.shipmentType === 'TL'
                        ? true
                        : false
                    }
                    onClick={e =>
                      handleTrackShipmentRequest(props?.rowData, e, 'track')
                    }
                    size='small'
                    style={{ marginLeft: 10 }}
                    variant='outlined'
                  >
                    {loader ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <CachedIcon sx={{ fontSize: 18, mr: 1 }} />
                        {trackShipmentData
                          ? trackShipmentData?.shipmentStatus === 0
                            ? 'Saved'
                            : trackShipmentData?.shipmentStatus === 1
                            ? 'Ready To Ship'
                            : trackShipmentData?.shipmentStatus === 2
                            ? 'Booked'
                            : trackShipmentData?.shipmentStatus === 3
                            ? 'InTransit'
                            : 'Delivered'
                          : rowData?.shipmentStatus}
                      </>
                    )}
                  </Button>
                  {trackShipmentData && (
                    <div>
                      <TrackShipmentDetails
                        trackShipmentData={trackShipmentData}
                        view={viewType}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col lg={3} xl={3}>
                  <div className='View_Details_Headings'>
                    Shipment Information
                  </div>
                </Col>
                <Col lg={3} xl={3}>
                  <Typography
                    sx={{ fontSize: '18', fontWeight: '500' }}
                    variant='h5'
                  >
                    {/* Shipment Information */}
                  </Typography>
                </Col>
                <Col lg={2} xl={2}>
                  <Typography
                    sx={{ fontSize: '18', fontWeight: '500' }}
                    variant='h5'
                  >
                    {/* Shipment Information */}
                  </Typography>
                </Col>
                <Col lg={3} xl={3}>
                  {trackShipmentData?.tracingLines !== null &&
                    trackShipmentData?.tracingLines !== undefined && (
                      <Button
                        onClick={e =>
                          handleTrackShipmentRequest(
                            props?.rowData,
                            e,
                            'history'
                          )
                        }
                        size='small'
                        style={{ marginLeft: 10 }}
                        variant='outlined'
                      >
                        View History
                      </Button>
                    )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={3} xl={3}>
                  <div>
                    <div className='View_Details_Headings'>
                      Total # of Pallets
                    </div>
                    <span className='View_Details'>
                      {rowData.details[0]?.pallets}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Weight</div>
                    <span className='View_Details'>
                      {rowData.details[0]?.netWeight}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Sale Order#</div>
                    <span className='View_Details'>{rowData.soNumber}</span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>PRO#</div>
                    <span className='View_Details'> {rowData.proNumber}</span>
                  </div>
                </Col>
                <Col lg={3} xl={3}>
                  <div>
                    <div className='View_Details_Headings'>
                      {' '}
                      Total # of Pieces
                    </div>

                    <span className='View_Details'>
                      {' '}
                      {rowData.details[0]?.pieces}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'> Freight Class</div>

                    <span className='View_Details'>
                      {' '}
                      {rowData.details[0]?.freightClass}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Purchase Order#</div>

                    <span className='View_Details'>
                      {' '}
                      {rowData.clientPONumber}
                    </span>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>BOL#</div>
                    <span className='View_Details'>{rowData.bolNumber}</span>
                  </div>
                </Col>
                <Col lg={2} xl={2}>
                  <div>
                    <div className='View_Details_Headings'>NMFC</div>

                    <span className='View_Details'>
                      {' '}
                      {rowData.details[0]?.nmfc}
                    </span>
                  </div>
                  <div style={{ marginTop: 10, whiteSpace: 'nowrap' }}>
                    <div className='View_Details_Headings'>Reference/Quote</div>

                    {rowData.quoteNumber}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'> Location Name</div>

                    <div className='View_Details'>{rowData.locationName}</div>
                  </div>

                  {rowData.shipmentType === 'TL' ? (
                    <div style={{ marginTop: 10 }}>
                      <div className='View_Details_Headings'>Service Type</div>
                      <span className='View_Details'>
                        {rowData.pickupNumber}
                      </span>
                    </div>
                  ) : (
                    <div style={{ marginTop: 10 }}>
                      <div className='View_Details_Headings'>Pickup#</div>

                      <span className='View_Details'>
                        {' '}
                        {rowData.pickupNumber}
                      </span>
                    </div>
                  )}
                </Col>
                <Col lg={3} xl={3}>
                  {viewType === 'history' && (
                    <div>
                      <TrackHistory
                        trackShipmentData={trackShipmentData}
                        view={viewType}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </TabPanelX>

            <TabPanelX value={valueX} index={1}>
              <div className='d-flex flex-column w-100 info-locations-tab'>
                <div className='d-flex flex-column'>
                  <Typography
                    variant='subtitle2'
                    className='mt-2 mb-0'
                    style={{ color: '#000000DE' }}
                  >
                    Origin
                  </Typography>
                  <hr className='mt-2' />
                  <div
                    className='d-flex w-100'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%', flexGrow: '1' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Name</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originContactPerson}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Address</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originAddress}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Phone</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originContactPhone}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Opening Time</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originOpeningTime}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      style={{ maxWidth: '50%', flexGrow: '1' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Company</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originCompany}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>
                          City/State/Zip/Country
                        </Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originCity}, {rowData.originState},{' '}
                          {rowData.originZipCode}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Email</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originContactEmail}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Closing Time</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.originClosingTime}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography
                    variant='subtitle2'
                    className='mt-4 mb-0'
                    style={{ color: '#000000DE' }}
                  >
                    Destination
                  </Typography>
                  <hr className='mt-2' />
                  <div
                    className='d-flex w-100'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%', flexGrow: '1' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Name</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationContactPerson}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Address</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationAddress}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Phone</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationContactPhone}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Opening Time</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationOpeningTime}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      style={{ maxWidth: '50%', flexGrow: '1' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Company</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationCompany}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>
                          City/State/Zip/Country
                        </Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationCity}, {rowData.destinationState},{' '}
                          {rowData.destinationZipCode}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Email</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationContactEmail}
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='caption'>Closing Time</Typography>
                        <Typography variant='h5' className='info-label-value'>
                          {rowData.destinationClosingTime}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanelX>

            <TabPanelX value={valueX} index={2}>
              <div
                className='d-flex w-100'
                style={{ justifyContent: 'space-between' }}
              >
                <div className='d-flex flex-column' style={{ flexGrow: '1' }}>
                  <div className='info-value-sec'>
                    <Typography variant='caption'>Commodity Type</Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {rowData.packagingType}
                    </Typography>
                  </div>
                  <div className='info-value-sec'>
                    <Typography variant='caption'>Weight (lbs)</Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {rowData?.details[0]?.netWeight}
                    </Typography>
                  </div>
                </div>
                <div className='d-flex flex-column' style={{ flexGrow: '1' }}>
                  <div className='info-value-sec'>
                    <Typography variant='caption'>Freight Class</Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {rowData?.details[0]?.freightClass}
                    </Typography>
                  </div>
                  <div className='info-value-sec'>
                    <Typography variant='caption'>NMFC</Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {rowData?.details[0]?.nmfc}
                    </Typography>
                  </div>
                </div>
                <div className='d-flex flex-column' style={{ flexGrow: '1' }}>
                  <div className='info-value-sec'>
                    <Typography variant='caption'>Total Weight</Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {rowData?.details[0]?.grossWeight}
                    </Typography>
                  </div>
                </div>
              </div>
            </TabPanelX>

            <TabPanelX value={valueX} index={3}>
              <div className='d-flex flex-column w-100 info-locations-tab'>
                <div className='d-flex flex-column'>
                  <Typography
                    variant='subtitle2'
                    className='mt-2 mb-0'
                    style={{ color: '#000000DE' }}
                  >
                    Pickup
                  </Typography>
                  <hr className='mt-2' />
                  <div
                    className='d-flex w-100'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Inside Pickup
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Limited Access
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Lift Gate Services
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Freeze Protection
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Residential Pickup
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Overlength - 500 Inch
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Secure Access
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography
                    variant='subtitle2'
                    className='mt-4 mb-0'
                    style={{ color: '#000000DE' }}
                  >
                    Delivery
                  </Typography>
                  <hr className='mt-2' />
                  <div
                    className='d-flex w-100'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Inside Pickup
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Limited Access
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Lift Gate Services
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Freeze Protection
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Residential Pickup
                        </Typography>
                      </div>
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Overlength - 500 Inch
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='d-flex flex-column'
                      sx={{ maxWidth: '50%' }}
                    >
                      <div className='info-value-sec'>
                        <Typography variant='body2' className=''>
                          Secure Access
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography
                    variant='subtitle2'
                    className='mt-4 mb-0'
                    style={{ color: '#000000DE' }}
                  >
                    Additional Fee
                  </Typography>
                  <hr className='mt-2' />

                  <div className='d-flex w-100' style={{ justifyContent: '' }}>
                    <div className='info-value-sec me-5'>
                      <Typography variant='caption'>
                        C. O. D Amounts($)
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>
                        75
                      </Typography>
                    </div>
                    <div className='info-value-sec'>
                      <Typography variant='caption'>
                        Declared Value($)
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>
                        63
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanelX>
            <TabPanelX value={valueX} index={3}>
              <div className='d-flex flex-column w-100 info-locations-tab'>
                {rowData?.carrierName ? rowData?.carrierName : ''}
              </div>
            </TabPanelX>
          </Box>
        </TabPanel>
        <TabPanel value={valueY} index={1}>
          {(shipmentStatus === 'InTransit' ||
            shipmentStatus === 'Delivered') && (
            <div>
              {!docLoader ? (
                historyError ? (
                  <Box
                    sx={{ textAlign: 'center' }}
                    className='Chat_Child_View_Shipment'
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <br />
                    <br /> */}
                    <WarningAmberIcon
                      style={{ fontSize: 150, color: '#D32F2F' }}
                    />{' '}
                    {/* <br /> */}
                    <br />
                    <Typography variant='h5'>
                      No history found for this shipment!
                    </Typography>
                    <br />
                    <br />
                    <br />
                  </Box>
                ) : (
                  <div className='Chat_Child_View_Shipment'>
                    {shipperApiMsgs &&
                      shipperApiMsgs.map(carrierRes => (
                        <div
                          style={
                            carrierRes.actionByFlag.toLowerCase() === 'carrier'
                              ? {
                                  display: 'flex',
                                  justifyContent: 'start',
                                  flexDirection: 'column',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  width: 400,
                                  position: 'relative'
                                }
                              : {
                                  display: 'flex',
                                  justifyContent: 'end',
                                  // flexDirection: 'column',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  position: 'relative'
                                }
                          }
                        >
                          {/* <div
                          className={
                            carrierRes.actionByFlag.toLowerCase() === 'carrier'
                              ? 'agency_manager_triangle'
                              : 'agency_carrier_triangle'
                          }
                        ></div> */}
                          {carrierRes.actionByFlag.toLowerCase() ===
                            'carrier' && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'start'
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#e0f2f1',
                                  borderRadius: '50%',
                                  width: '45',
                                  height: '45',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginRight: 10
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: '50%',
                                    width: '45',
                                    height: '45',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    objectFit: 'cover'
                                  }}
                                  alt=''
                                  src={`https://tms.dynamicwerx.com/${carrierApiMsgs[0]?.actionByImage}`}
                                />
                              </div>
                              <div>
                                <b style={{ fontSize: 18 }}>
                                  {carrierApiMsgs &&
                                    carrierApiMsgs[0]?.actionByName}
                                </b>
                                <br />
                                <span style={{ fontWeight: 600 }}>
                                  {carrierApiMsgs[0]?.actionByDesignation}
                                </span>
                              </div>
                            </div>
                          )}
                          <div>
                            {carrierRes.actionByFlag.toLowerCase() ===
                              'shipper' && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end'
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: 0,
                                    marginRight: 1,
                                    textAlign: 'right'
                                  }}
                                >
                                  <b style={{ fontSize: 18 }}>
                                    {shipperApiMsgs &&
                                      shipperApiMsgs[0]?.actionByName}
                                  </b>
                                  <br />
                                  <span style={{ fontWeight: 600 }}>
                                    {shipperApiMsgs[0]?.actionByDesignation}
                                  </span>
                                  <br />
                                </div>
                                <div
                                  style={{
                                    backgroundColor: '#e0f2f1',
                                    borderRadius: '50%',
                                    width: '45',
                                    height: '45',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginLeft: 10
                                  }}
                                >
                                  <img
                                    style={{
                                      borderRadius: '50%',
                                      width: '45',
                                      height: '45',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      objectFit: 'cover'
                                    }}
                                    alt=''
                                    src={`https://tms.dynamicwerx.com/${shipperApiMsgs[0]?.actionByImage}`}
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              style={
                                carrierRes.actionByFlag.toLowerCase() ===
                                'shipper'
                                  ? {
                                      marginBottom: '10vh',
                                      backgroundColor: '#e0f2f1',
                                      borderRadius: '10px 0px 10px 10px',
                                      padding: '10px',
                                      transform: '(rotateX(180deg)'
                                    }
                                  : {
                                      marginBottom: '10vh',
                                      backgroundColor: '#eceff1',
                                      padding: '10px',
                                      borderRadius: '0px 10px 10px 10px'
                                    }
                              }
                            >
                              <div
                                style={{ fontSize: 12 }}
                                dangerouslySetInnerHTML={{
                                  __html: handleCorresHistory(
                                    carrierRes.actionData
                                  )
                                }}
                              ></div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  fontSize: 11
                                }}
                              >
                                {handleDateTimeShow(carrierRes.date, 'time')}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {/* <div ref={bottomRef} /> */}
                  </div>
                )
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40vw',
                    height: '90%'
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          )}
        </TabPanel>
        <TabPanel value={valueY} index={2}>
          {shipmentStatus !== 'Saved' && (
            <div>
              {blobUrlPOD !== '' ? (
                <iframe
                  src={blobUrlPOD}
                  title='POD Viewer'
                  style={{ width: '40vw', border: 'none' }}
                  width='100%'
                  height='90%'
                ></iframe>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40vw',
                    height: '90%'
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          )}
        </TabPanel>
        <TabPanel value={valueY} index={3}>
          Item Four
        </TabPanel>
        <TabPanel value={valueY} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={valueY} index={5}>
          Item Six
        </TabPanel>
      </Box>
    </div>
  );
}

export default ViewShipment;

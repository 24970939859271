import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Chart } from 'react-google-charts';
import ReactSpeedometer from 'react-d3-speedometer';
import { Badge } from 'reactstrap';
// import FalconDashboard from '../../public/dashboard/analytics.html';

function Dashboard() {
  const data = [
    ['Title', 'Sales'],
    ['Polymer, $11.6M', 11.6],
    ['PUR, $3M', 3],
    ['WB, $6.4', 6.4],
    ['HM, $7.9', 7.9]
  ];
  const data2 = [
    ['Title', 'Sales'],
    ['Polymer, $226.5M', 226.5],
    ['PUR, $40.8M', 40.8],
    ['WB, $126.9', 126.9],
    ['HM, $115.7', 115.7]
  ];

  const options1 = {
    title: 'Overall Cost by Technology',
    is3D: true
  };

  const options2 = {
    title: 'Overall LBS Shipped',
    is3D: true
  };
  const styles = {
    dial: {
      display: 'inline-block',
      width: `300px`,
      height: `auto`,
      color: '#000',
      border: '0.5px solid #fff',
      padding: '2px'
    },
    title: {
      fontSize: '1em',
      color: '#000'
    }
  };
  const BarChartData = [
    ['Garland', '', ''],
    ['Mid West', 0.1, 0.15],
    ['North East', 0.05, 0.15],
    ['South East', 0.1, 0.16],
    ['South West', 0.15, 0.23],
    ['West', 0.2, 0.12]
  ];

  // data = [
  //   ["Year", "Sales", "Expenses", "Profit"],
  //   ["2014", 1000, 400, 200],
  //   ["2015", 1170, 460, 250],
  //   ["2016", 660, 1120, 300],
  //   ["2017", 1030, 540, 350],
  // ];
  const BarChartOptions = {
    chart: {
      title: ''
      // subtitle: 'in millions of dollars (USD)'
    }
  };
  const lineChartData = [
    ['Month', '$'],
    ['Jan', 0.063],
    ['Feb', 0.066],
    ['Mar', 0.067],
    ['Apr', 0.065],
    ['May', 0.064],
    ['Jun', 0.066],
    ['Jul', 0.064],
    ['Aug', 0.063],
    ['Sep', 0.065],
    ['Oct', 0.063],
    ['Nov', 0.065],
    ['Dec', 0.066]
  ];
  const lineChartOptions = {
    chart: {
      title: 'Freight / LBS'
      // subtitle: 'in millions of dollars (USD)'
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        height: '90vh',
        overflowY: 'scroll'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 1
        }}
      >
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div>
            <Typography variant='h5'>
              <Badge color='primary'>Freight Cost</Badge>
            </Typography>
            <Typography variant='body1' sx={{ m: 1 }}>
              {' '}
              <Badge>$31,658,566</Badge>
            </Typography>
          </div>
          <Chart
            className='box_shadow'
            style={{ textAlign: 'center', alignSelf: 'center' }}
            width={'700px'}
            height={'400px'}
            chartType='PieChart'
            // loader={<div>Loading Chart</div>}
            data={data}
            options={options1}
          />
        </div>

        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div>
            <Typography variant='h5'>
              <Badge color='warning'>Qty Shipped</Badge>
            </Typography>
            <Typography variant='body1' sx={{ m: 1 }}>
              <Badge>$13,995,396</Badge>
            </Typography>
          </div>
          <Chart
            className='box_shadow'
            width={'700px'}
            height={'400px'}
            chartType='PieChart'
            // loader={<div>Loading Chart</div>}
            data={data2}
            options={options2}
          />
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          m: ' 20 0 10 0'
        }}
      >
        <div
          className='box_shadow'
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            backgroundColor: 'white'
          }}
        >
          <ReactSpeedometer
            width={700}
            height={400}
            needleHeightRatio={0.8}
            value={777}
            currentValueText='$0.062 per Lbs'
            customSegmentLabels={[
              {
                text: 'Very Bad',
                position: 'INSIDE',
                color: '#555'
              },
              {
                text: 'Bad',
                position: 'INSIDE',
                color: '#555'
              },
              {
                text: 'Ok',
                position: 'INSIDE',
                color: '#555',
                fontSize: '19px'
              },
              {
                text: 'Good',
                position: 'INSIDE',
                color: '#555'
              },
              {
                text: 'Very Good',
                position: 'INSIDE',
                color: '#555'
              }
            ]}
            ringWidth={47}
            needleTransitionDuration={8888}
            needleTransition='easeElastic'
            needleColor={'#90f2ff'}
          />
        </div>
        <div
          className='box_shadow'
          style={{
            backgroundColor: 'white',
            width: 700,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex' }}>
            <div>
              <ReactSpeedometer
                width={270}
                height={190}
                labelFontSize={'10px'}
                valueTextFontSize={'14px'}
                valueTextFontWeight={'200'}
                // paddingHorizontal={17}
                // paddingVertical={17}
                needleHeightRatio={0.5}
                value={87}
                currentValueText='Polymer = $0.051'
                customSegmentLabels={[
                  {
                    text: 'Very Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Ok',
                    position: 'INSIDE',
                    color: '#555',
                    fontSize: '19px'
                  },
                  {
                    text: 'Good',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Very Good',
                    position: 'INSIDE',
                    color: '#555'
                  }
                ]}
                ringWidth={47}
                needleTransitionDuration={8888}
                needleTransition='easeElastic'
                needleColor={'#90f2ff'}
              />
            </div>
            <div>
              <ReactSpeedometer
                width={270}
                height={190}
                labelFontSize={'10px'}
                valueTextFontSize={'14px'}
                valueTextFontWeight={'200'}
                // paddingHorizontal={17}
                // paddingVertical={17}
                needleHeightRatio={0.5}
                value={667}
                currentValueText='PUR = $0.097'
                customSegmentLabels={[
                  {
                    text: 'Very Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Ok',
                    position: 'INSIDE',
                    color: '#555',
                    fontSize: '19px'
                  },
                  {
                    text: 'Good',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Very Good',
                    position: 'INSIDE',
                    color: '#555'
                  }
                ]}
                ringWidth={47}
                needleTransitionDuration={8888}
                needleTransition='easeElastic'
                needleColor={'#90f2ff'}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <ReactSpeedometer
                width={270}
                height={190}
                labelFontSize={'10px'}
                valueTextFontSize={'14px'}
                valueTextFontWeight={'200'}
                // paddingHorizontal={17}
                // paddingVertical={17}
                needleHeightRatio={0.5}
                value={87}
                currentValueText='WB = $0.051'
                customSegmentLabels={[
                  {
                    text: 'Very Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Ok',
                    position: 'INSIDE',
                    color: '#555',
                    fontSize: '19px'
                  },
                  {
                    text: 'Good',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Very Good',
                    position: 'INSIDE',
                    color: '#555'
                  }
                ]}
                ringWidth={47}
                needleTransitionDuration={8888}
                needleTransition='easeElastic'
                needleColor={'#90f2ff'}
              />
            </div>
            <div>
              <ReactSpeedometer
                width={270}
                height={190}
                labelFontSize={'10px'}
                valueTextFontSize={'14px'}
                valueTextFontWeight={'200'}
                // paddingHorizontal={17}
                // paddingVertical={17}
                needleHeightRatio={0.5}
                value={457}
                currentValueText='HM = $0.084'
                customSegmentLabels={[
                  {
                    text: 'Very Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Bad',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Ok',
                    position: 'INSIDE',
                    color: '#555',
                    fontSize: '19px'
                  },
                  {
                    text: 'Good',
                    position: 'INSIDE',
                    color: '#555'
                  },
                  {
                    text: 'Very Good',
                    position: 'INSIDE',
                    color: '#555'
                  }
                ]}
                ringWidth={47}
                needleTransitionDuration={8888}
                needleTransition='easeElastic'
                needleColor={'#90f2ff'}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          m: ' 20 0 10 0'
        }}
      >
        <Chart
          className='box_shadow'
          chartType='AreaChart'
          width={'700px'}
          height={'400px'}
          data={lineChartData}
          options={lineChartOptions}
        />
        <Chart
          className='box_shadow'
          chartType='Bar'
          width={'700px'}
          height={'400px'}
          data={BarChartData}
          options={BarChartOptions}
        />
      </Box>
    </Box>
  );
}

export default Dashboard;

import React, { useEffect, useRef } from 'react';
import { Alert, Box, Button, Skeleton, Tab, Tabs } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import './GetRates.css';
import { useState } from 'react';
import { Typography } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import SendIcon from '@mui/icons-material/Send';
import {
  GetQuote,
  GetQuoteDetail,
  LogTLShipperResponse,
  ResendQuote
} from '../../apis/AgencyWithoutAuthHeaders';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { successToast } from '../../components/Toasts';

function ShipmentTabPanel({
  previousRowDetails,
  handleViewTLRates,
  TLQuoteId,
  carriers,
  handleListItemClick,
  selectedIndex,
  isLoading,
  ApiFailed,
  ApiErrorMessage
}) {
  const [negotitateRate, setNegotiateRate] = useState(false);
  const [carrierName, setCarrierName] = useState('');
  const [carrierLogo, setCarrierLogo] = useState('');
  const [userInput, setUserInput] = useState('');
  const [userInputArray, setUserInputArray] = useState([]);
  const [showInputValue, setShowInputValue] = useState(false);
  const [shipperSessionId, setShipperSessionId] = useState('');

  const [htmlApiRes, setHtmlApiRes] = useState('');
  const [shipperApiMsgs, setShipperApiMsgs] = useState('');
  const [carrierApiMsgs, setCarrierApiMsgs] = useState('');
  const [filteredCarriers, setFilteredCarriers] = useState([]);

  const customSort = (a, b) => {
    const order = ['RESPONDED', 'PENDING'];
    const indexA = order.indexOf(a.carrierResponseStatus);
    const indexB = order.indexOf(b.carrierResponseStatus);
    return indexB - indexA;
  };
  useEffect(() => {
    // let tempcarriers = carriers.sort(
    //   (a, b) => a.active.quoteValue - b.active.quoteValue
    // );
    const sortedArray = carriers.sort(customSort);
    setFilteredCarriers(sortedArray);
  }, [carriers]);

  useEffect(() => {
    const sortedItems = filteredCarriers.sort((a, b) => {
      if (
        a.carrierResponseStatus === 'PENDING' &&
        b.carrierResponseStatus !== 'PENDING'
      ) {
        return 1; // "pending" item comes last
      } else if (
        a.carrierResponseStatus !== 'PENDING' &&
        b.carrierResponseStatus === 'PENDING'
      ) {
        return -1; // "pending" item comes first
      } else {
        return 0; // No change in order for other statuses
      }
    });
    setFilteredCarriers(sortedItems);
  }, [filteredCarriers]);

  const handleClick = e => {
    e.preventDefault();
    // Perform your desired action on item click
    // console.log('Item clicked:', item);
  };
  const handleSuccessResponse = (apiRes, action) => {
    console.log(apiRes.data.data.details, 'apiResapiRes');
    action === 'resend' &&
      successToast('The email has been successfully resent.');
    const shipperData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'shipper'
    );
    const carrierData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'carrier'
    );
    // setHtmlApiRes(apiRes.data.data);
    setShipperApiMsgs(apiRes.data.data.details);
    setCarrierApiMsgs(carrierData);
    // setHtmlApiRes(apiRes.data.data.actionData);
    // setActionByName(apiRes.data.data.actionBy);
  };
  const ShipperQuoteReqHandler = (shipperSessionId, action) => {
    GetQuoteDetail(shipperSessionId)
      .then(res => res.status === 200 && handleSuccessResponse(res, action))
      .catch(err => console.log(err, 'QuoteTLErr------>>>.'));
  };
  const handleNegotiateRate = (e, items, action) => {
    setShipperSessionId(items.sessionId);
    if (
      (items?.carrierResponseStatus?.toLowerCase() === 'responded' ||
        items?.carrierResponseStatus?.toLowerCase() === 'accepted') &&
      action === 'update_chat'
    ) {
      ShipperQuoteReqHandler(items.sessionId);
    }
    if (
      items?.carrierResponseStatus?.toLowerCase() === 'pending' &&
      action === 'update_chat'
    ) {
      ResendQuote(items.sessionId)
        .then(
          res =>
            res.status === 200 &&
            ShipperQuoteReqHandler(items.sessionId, 'resend')
        )
        .catch(err => console.log(err, 'Resend Err---->>>>'));
    }

    setCarrierName(items?.carrierName);
    setCarrierLogo(items?.logo);
    if (
      action === 'view_chat' ||
      items?.carrierResponseStatus?.toLowerCase() === 'responded' ||
      items?.carrierResponseStatus?.toLowerCase() === 'accepted'
    ) {
      setNegotiateRate(true);
    }
  };

  const handleUserInput = input => {
    setUserInput(input);
  };

  const handleChatRequestApi = () => {
    const chatObj = {
      sessionId: shipperSessionId,
      charges: 0,
      pickupNumber: '',
      pickupDate: '',
      estimatedDeliveryDate: '',
      comments: userInput
    };
    LogTLShipperResponse(chatObj)
      .then(
        res => res.status === 200 && ShipperQuoteReqHandler(shipperSessionId)
      )
      .catch(err => console.log(err, 'chatApiErr---->>'));
  };
  const handleShowUserInputMsgs = () => {
    setShowInputValue(true);
    // let temp = [...userInputArray];
    // temp.push({ msg: userInput });
    // setUserInputArray(temp);
    getCurrentDateTime();
    setUserInput('');
    if (userInput !== '') {
      handleChatRequestApi();
    }
  };
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleShowUserInputMsgs();
    }
  };
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    // alert('scrollled');
    bottomRef.current &&
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Scroll to the bottom whenever userInputArray is updated
    scrollToBottom();
  }, [shipperApiMsgs, negotitateRate]);

  const getCurrentDateTime = (() => {
    let initialTimestamp = null;

    return () => {
      if (!initialTimestamp) {
        initialTimestamp = new Date();
        let temp = [...userInputArray];
        temp.push({ timeStamp: initialTimestamp, msg: userInput });
        // temp.push({ msg: userInput });
        setUserInputArray(temp);
      }

      const now = new Date();
      const diff = now - initialTimestamp;

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      const dayName = daysOfWeek[initialTimestamp.getDay()];
      const monthName = months[initialTimestamp.getMonth()];
      const day = initialTimestamp.getDate();
      const year = initialTimestamp.getFullYear();

      const hours = initialTimestamp.getHours();
      const minutes = initialTimestamp.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? '0' : ''
      }${minutes} ${ampm}`;
      let temp = [...userInputArray];
      temp.push({
        timeStamp: ` ${monthName} ${day}, ${year} ${formattedTime}`,
        msg: userInput
      });
      // temp.push({ msg: userInput });
      setUserInputArray(temp);
      // return ` ${monthName} ${day}, ${year} ${formattedTime}`;
    };
  })();

  function formatDateTime(dateTime) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const dayName = daysOfWeek[dateTime.getDay()];
    const monthName = months[dateTime.getMonth()];
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();

    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${ampm}`;

    return `${dayName}, ${monthName} ${day}, ${year} ${formattedTime}`;
  }

  const handleSynchRatesRequest = () => {
    setSynchRotate(true);
    GetQuote(TLQuoteId)
      .then(res => {
        res.status === 200 && handleViewTLRates(res.data.data);
        setSynchRotate(false);
      })
      .catch(err => console.log(err, 'TL quote Err----->>>>'));
  };

  const handleDateTimeShow = (timeStamp, type) => {
    const dateTimeString = timeStamp + 'Z';
    const dateTime = new Date(dateTimeString);
    const date = new Date(dateTimeString);
    // const timeOptions = {
    //   hour: 'numeric',
    //   minute: '2-digit',
    //   // second: '2-digit',
    //   hour12: true,
    //   weekday: 'short',
    //   // year: 'numeric',
    //   month: 'short',
    //   day: 'numeric'
    // };
    const dateOptions = {
      // weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeOptions = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(dateTime);

    const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);
    const formattedDate = date.toLocaleDateString(undefined, dateOptions);

    if (type === 'time') {
      return timeOptions;
    }
    if (type === 'date') {
      return formattedDate;
    }
  };
  const [sortValue, setSortValue] = useState(0);
  const [filterKey, setFilterKey] = useState('*');
  const [sortKey, setSortKey] = useState('price');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [synchRotate, setSynchRotate] = useState(false);
  const handleSortToggle = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };
  const handleSortValueChange = (event, newValue) => {
    setSortValue(newValue);
  };
  const applyStatusSort = (a, b) => {
    if (
      a.carrierResponseStatus === 'PENDING' &&
      b.carrierResponseStatus !== 'PENDING'
    ) {
      return 1; // "pending" item comes last
    } else if (
      a.carrierResponseStatus !== 'PENDING' &&
      b.carrierResponseStatus === 'PENDING'
    ) {
      return -1; // "pending" item comes first
    } else {
      return 0; // No change in order for other statuses
    }
  };
  const applySort = (rowA, rowB) => {
    switch (sortKey) {
      case 'price':
        return sortOrder === 'ASC'
          ? rowA.quoteValue - rowB.quoteValue
          : rowB.quoteValue - rowA.quoteValue;
      case 'days':
        const dateA = new Date(rowA.deliveryDate);
        const dateB = new Date(rowB.deliveryDate);

        const daysA = isNaN(dateA.getTime()) ? 9999 : dateA.getTime();
        const daysB = isNaN(dateB.getTime()) ? 9999 : dateB.getTime();

        return sortOrder === 'ASC' ? daysA - daysB : daysB - daysA;
      case 'name':
        return sortOrder === 'ASC'
          ? rowA.carrierName.localeCompare(rowB.carrierName)
          : rowB.carrierName.localeCompare(rowA.carrierName);
      default:
        return 0; // Default case: no sorting
    }
  };

  const sortedItems = filteredCarriers.sort(applySort).sort(applyStatusSort);
  const removeTableCss = finalRes => {
    const regexPattern = /<style[^>]*>([\s\S]*?)<\/style>/gi;

    // Remove table styles including borders
    const updatedHtml = finalRes.replace(regexPattern, (match, styles) => {
      const stylesWithoutTable = styles.replace(
        /(?:table|td|th)\s*\{[^}]*\}/gi,
        ''
      );
      return `<style>${stylesWithoutTable} table { font-size: 12px; } td { padding: 5px; }</style>`;
    });
    return updatedHtml;
  };

  const handleCorresHistory = apiRes => {
    // Regex pattern to capture and replace font-size in table styles
    const fontSizeToUpdate = removeTableCss(apiRes);

    return fontSizeToUpdate;

    // removeTableCss(updatedHtml);
  };
  return (
    <div>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        style={{ width: 'auto' }}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        {/* <hr /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
            boxShadow: '0.5px 0.5px 2px 0px',
            borderRadius: 4,
            height: 30
          }}
        >
          <div>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Order Number:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.soNumber}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Ship from:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.originCity +
                  ' ' +
                  previousRowDetails.originState +
                  ' ' +
                  previousRowDetails.originZipCode}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Ship to:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.destinationCity +
                  ' ' +
                  previousRowDetails.destinationState +
                  ' ' +
                  previousRowDetails.destinationZipCode}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Ship to Company:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.destinationCompany}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Weight:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.details[0].netWeight} lbs
              </span>
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
            boxShadow: '0.5px 0.5px 2px 0px',
            borderRadius: 8
            // height: 35
          }}
        >
          <div>
            <Button
              sx={{ textTransform: 'capitalize', height: 25 }}
              variant='outlined'
              size='medium'
            >
              <Typography
                variant='h6'
                className='fw-bold text-black me-2'
                style={{ cursor: 'pointer', fontSize: '13px' }}
                onClick={() => handleSynchRatesRequest()}
                // onClick={() => window.location.reload()}
              >
                {synchRotate ? (
                  <RefreshIcon
                    sx={{ transform: 'rotateX(-190deg)', fontSize: '16px' }}
                  />
                ) : (
                  <RefreshIcon sx={{ fontSize: '16px' }} />
                )}
                Refresh Rates
              </Typography>
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            {' '}
            <Box className='d-flex' sx={{ alignItems: 'center' }}>
              <Typography
                variant='h6'
                className='fw-bold text-black me-2'
                style={{ cursor: 'pointer', fontSize: '13px' }}
                onClick={handleSortToggle}
              >
                {sortOrder === 'ASC' ? (
                  <SortIcon
                    style={{ transform: 'rotateX(180deg)', fontSize: '16px' }}
                  />
                ) : (
                  <SortIcon style={{ fontSize: '16px' }} />
                )}{' '}
                Sort
              </Typography>
              <Tabs
                onChange={handleSortValueChange}
                value={sortValue}
                aria-label='Tabs where selection follows focus'
                selectionFollowsFocus
              >
                {/* icon={sortOrder === 'ASC' ? <SortIcon style={{ transform: 'rotateX(180deg)' }} /> : <SortIcon />} */}
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label='Price'
                  onClick={() => setSortKey('price')}
                />
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label='Dates'
                  onClick={() => setSortKey('days')}
                />
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label={sortOrder === 'ASC' ? 'Carrier A-Z' : 'Carrier Z-A'}
                  onClick={() => setSortKey('name')}
                />
              </Tabs>
            </Box>
          </div>
        </div>
        <hr />
        <Col xl={negotitateRate ? 7 : 12} lg={negotitateRate ? 7 : 12}>
          <div
            className='scroll'
            style={{ height: '60vh', overflowX: 'hidden', overflowY: 'scroll' }}
            // ref={divRef}
            // onScroll={() => setScrollPosition(divRef.current.scrollTop)}
            // onClick={handleButtonClick}
          >
            {isLoading ? (
              <div
                style={{ margin: 5 }}
                // className='Error_Carrier_div_LTL'
                className={
                  negotitateRate
                    ? 'List_Carrier_div_Negotiate'
                    : 'List_Carrier_div'
                }
              >
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={
                      isLoading
                        ? {
                            width: 60,
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                        : {
                            width: 'auto',
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                    }
                  ></div>
                  <Col lg={3}>
                    <Box
                      sx={{
                        height: 60,
                        margin: '20 0 0 17'
                      }}
                    >
                      <Skeleton variant='circular' width={40} height={40} />
                    </Box>
                  </Col>
                  <Col lg={3}>
                    <div style={{ cursor: 'pointer' }}>
                      <h2>
                        <Box sx={{ width: 120 }}>
                          <Skeleton />
                        </Box>
                      </h2>

                      <span
                        style={{
                          width: 'auto',
                          fontSize: 10,
                          fontWeight: 700,
                          position: 'absolute',
                          margin: '-14 0 0 0',
                          cursor: 'auto'
                        }}
                      >
                        <Box sx={{ width: 100, marginTop: 0.5 }}>
                          <Skeleton />
                        </Box>
                      </span>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 15
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700
                          }}
                        >
                          <Box sx={{ width: 110 }}>
                            <Skeleton />
                          </Box>
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal'
                            }}
                          >
                            <Box sx={{ width: 80, marginTop: -2 }}>
                              <Skeleton />
                            </Box>
                          </span>
                        </span>
                      </div>{' '}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          margin: '0 0 0 18'
                        }}
                      >
                        <Box sx={{ width: 110, marginLeft: 3 }}>
                          <Skeleton />
                        </Box>

                        <br />
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 'normal',
                            margin: '0 0 0 18'
                          }}
                        >
                          <Box
                            sx={{
                              width: 80,
                              marginTop: -2,
                              marginLeft: 3
                            }}
                          >
                            <Skeleton />
                          </Box>
                        </span>
                      </span>
                    </div>{' '}
                  </Col>
                </Row>
              </div>
            ) : (
              sortedItems.map((items, index) => {
                if (items) {
                  return (
                    <div
                      style={{
                        // display: 'flex',
                        // justifyContent: 'flex-start',
                        margin: '15 10 0 0'
                      }}
                    >
                      <List>
                        <ListItemButton
                          className={
                            negotitateRate
                              ? 'List_Carrier_div_Negotiate'
                              : 'List_Carrier_div'
                          }
                          onMouseDown={handleClick}
                          selected={selectedIndex === index}
                          onClick={event => {
                            handleListItemClick(event, index, items);
                            // handleNegotiateRate(event, items, 'view_chat');
                          }}
                        >
                          <Row
                            key={index}
                            className='Carrier_div'
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Col>
                              <div style={{ display: 'flex' }}>
                                <div>
                                  <img
                                    style={{
                                      height: 60,
                                      width: '50',
                                      objectFit: 'contain'
                                    }}
                                    src={`https://tms.dynamicwerx.com/${items.carrierLogo}`}
                                    alt={''}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    marginTop: 20,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                >
                                  <h5>{items.carrierName}</h5>
                                </div>
                              </div>
                            </Col>

                            <Col>
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginRight: '35%'
                                }}
                              >
                                <div>
                                  <h5>
                                    {items?.carrierResponseStatus?.toLowerCase() ===
                                      'responded' ||
                                    items?.carrierResponseStatus?.toLowerCase() ===
                                      'accepted'
                                      ? `$${items?.quoteValue}`
                                      : 'PENDING'}
                                  </h5>
                                </div>
                                <Button
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleNegotiateRate(
                                      e,
                                      items,
                                      'update_chat'
                                    );
                                  }}
                                  sx={{
                                    width: 90,
                                    fontSize: 9,
                                    textTransform: 'capitalize'
                                  }}
                                  variant='contained'
                                  size='small'
                                >
                                  {items?.carrierResponseStatus?.toLowerCase() ===
                                    'responded' ||
                                  items?.carrierResponseStatus?.toLowerCase() ===
                                    'accepted'
                                    ? 'Negotiate'
                                    : 'Resend'}
                                </Button>
                                <br />
                                {items?.referenceNumber && (
                                  <div style={{ marginTop: 5, fontSize: 12 }}>
                                    <b>Service type: </b>
                                    {items?.referenceNumber}
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col>
                              <h6>Pickup Date</h6>
                              <h7>
                                {items?.carrierResponseStatus?.toLowerCase() ===
                                  'responded' ||
                                items?.carrierResponseStatus?.toLowerCase() ===
                                  'accepted'
                                  ? handleDateTimeShow(
                                      items?.pickupDate,
                                      'date'
                                    )
                                  : 'PENDING'}
                              </h7>
                            </Col>
                            <Col>
                              <h6>Est Delivery Date</h6>
                              {/* <br /> */}
                              <h7>
                                {items?.carrierResponseStatus?.toLowerCase() ===
                                  'responded' ||
                                items?.carrierResponseStatus?.toLowerCase() ===
                                  'accepted'
                                  ? handleDateTimeShow(
                                      items?.deliveryDate,
                                      'date'
                                    )
                                  : 'PENDING'}
                              </h7>
                            </Col>
                          </Row>
                        </ListItemButton>
                      </List>
                    </div>
                  );
                }
              })
            )}
            {ApiFailed && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10%'
                }}
              >
                {/* <div class='alert alert-danger' role='alert'>
                  {ApiErrorMessage}
                </div> */}
                <Alert severity='error'>{ApiErrorMessage}</Alert>
              </div>
            )}
          </div>
        </Col>
        {negotitateRate && (
          <Col xl={5} lg={5}>
            <div className='Chat_Parent'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant='h6'>Chat</Typography>
                  <SyncOutlinedIcon
                    onClick={() => ShipperQuoteReqHandler(shipperSessionId)}
                    sx={{ cursor: 'pointer', color: '#4e79d2' }}
                  />
                </div>
                <CancelIcon
                  onClick={() => {
                    setNegotiateRate(false);
                    setHtmlApiRes('');
                  }}
                  sx={{ cursor: 'pointer', color: '#4e79d2' }}
                />
              </div>
              {/* <hr /> */}
              <div className='Chat_Child'>
                {shipperApiMsgs &&
                  shipperApiMsgs.map(carrierRes => (
                    <div
                      style={
                        carrierRes.actionByFlag.toLowerCase() === 'carrier'
                          ? {
                              display: 'flex',
                              justifyContent: 'start',
                              flexDirection: 'column',
                              marginLeft: 10,
                              marginRight: 10,
                              width: 400,
                              position: 'relative'
                            }
                          : {
                              display: 'flex',
                              justifyContent: 'end',
                              // flexDirection: 'column',
                              marginLeft: 10,
                              marginRight: 10,
                              position: 'relative'
                            }
                      }
                    >
                      {/* <div
                        className={
                          carrierRes.actionByFlag.toLowerCase() === 'carrier'
                            ? 'agency_manager_triangle'
                            : 'agency_carrier_triangle'
                        }
                      ></div> */}
                      {carrierRes.actionByFlag.toLowerCase() === 'carrier' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#eceff1',
                              borderRadius: '50%',
                              width: '45',
                              height: '45',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              marginRight: 10
                            }}
                          >
                            <img
                              style={{
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                objectFit: 'cover'
                              }}
                              alt=''
                              src={`https://tms.dynamicwerx.com/${carrierApiMsgs[0]?.actionByImage}`}
                            />
                          </div>
                          <div>
                            <b style={{ fontSize: 18 }}>
                              {carrierApiMsgs &&
                                carrierApiMsgs[0]?.actionByName}
                            </b>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                              {carrierApiMsgs[0]?.actionByDesignation}
                            </span>
                          </div>
                        </div>
                      )}
                      <div>
                        {carrierRes.actionByFlag.toLowerCase() ===
                          'shipper' && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'end'
                            }}
                          >
                            <div
                              style={{
                                marginLeft: 0,
                                marginRight: 1,
                                textAlign: 'right'
                              }}
                            >
                              <b style={{ fontSize: 18 }}>
                                {shipperApiMsgs &&
                                  shipperApiMsgs[0]?.actionByName}
                              </b>
                              <br />
                              <span style={{ fontWeight: 600 }}>
                                {shipperApiMsgs[0]?.actionByDesignation}
                              </span>
                              <br />
                            </div>
                            <div
                              style={{
                                backgroundColor: '#e0f2f1',
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 10
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: '50%',
                                  width: '45',
                                  height: '45',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  objectFit: 'cover'
                                }}
                                alt=''
                                src={`https://tms.dynamicwerx.com/${shipperApiMsgs[0]?.actionByImage}`}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          style={
                            carrierRes.actionByFlag.toLowerCase() === 'shipper'
                              ? {
                                  marginBottom: '10vh',
                                  backgroundColor: '#e0f2f1',
                                  borderRadius: '10px 0px 10px 10px',
                                  padding: '10px',
                                  transform: '(rotateX(180deg)'
                                }
                              : {
                                  marginLeft: 1,
                                  marginBottom: '10vh',
                                  backgroundColor: '#eceff1',
                                  padding: '10px',
                                  borderRadius: '0px 10px 10px 10px'
                                }
                          }
                        >
                          <div
                            style={{ fontSize: 13 }}
                            dangerouslySetInnerHTML={{
                              __html: handleCorresHistory(carrierRes.actionData)
                            }}
                          ></div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontSize: 11
                            }}
                          >
                            {handleDateTimeShow(carrierRes.date, 'time')}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <div ref={bottomRef} />
              </div>

              <div className='User_Input'>
                <input
                  className='User_Input'
                  placeholder='Type your message here ...'
                  type='text'
                  id='userInput'
                  name='userInput'
                  value={userInput}
                  onChange={e => handleUserInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <SendIcon
                  id='submitButton'
                  onClick={handleShowUserInputMsgs}
                  // onClick={() => handleShowUserInputMsgs()}
                  sx={{ fontSize: 30, marginBottom: 1 }}
                  className='User_Input_Icon'
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ShipmentTabPanel;

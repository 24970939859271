import React, { useEffect, useRef } from 'react';
import { Paper, CardContent, Card } from '@mui/material';
import { Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import './GetRates.css';
import { useSelector } from 'react-redux';
import { GetActiveCarriers } from '../../apis/Agency';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { base_url } from '../../apis/constants';
import QuickQuoteTabPanel from './QuickQuoteTabPanel';

function QuickQuoteRates(props) {
  const obj = props.location.state.state;
  const deepCloneObj = props.location.state.deepClone;
  const totalWeightLBS = props.location.state.totalLBSWeight;
  const quote = props.location.state.quote;
  const SelectedRowData = props.location?.state?.DataToShow;
  const selectedClass =
    props.location?.state?.state?.commodityInfo[0].freightClass;
  const shipTypeValue = props.location?.state?.shipTypeValue;
  const [EstesApiLoading, setEstesApiLoading] = useState(false);
  const locationId = props.location?.state?.locationId;

  const [GetRatesArray, setGetRatesArray] = useState([]);
  const [finalCarrierApis, setFinalCarrierApis] = useState([]);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [data, setData] = useState([]);
  const [errorMessageApi, setErrorMessageApi] = useState([]);

  const [successRates, setSuccessRates] = useState([]);
  const [noAssignedCarriers, setNoAssignedCarriers] = useState(null);

  const dispatch = useDispatch();
  // const obj = props.location.state;
  const OriginLocation =
    obj.shipFrom.city +
    ' ' +
    obj.shipFrom.stateOrProvinceCode +
    ' ' +
    obj.shipFrom.postalCode;
  // +
  // ' ' +
  // obj.shipFrom.countryCode;
  const DestinationLocation =
    // obj.shipTo.postalCode +
    // ' ' +
    obj.shipTo.city +
    ' ' +
    obj.shipTo.stateOrProvinceCode +
    ' ' +
    obj.shipTo.postalCode;
  // obj.shipTo.countryCode;

  const OrderWeight = totalWeightLBS;
  const [originLoc, setOriginLoc] = useState(OriginLocation);
  const [destinationLoc, setDestinationLoc] = useState(DestinationLocation);
  const [requoteWeight, setRequoteWeight] = useState(OrderWeight);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  const options = {
    // year: '2-digit',
    month: 'short',
    day: 'numeric'
  };

  const uniqueIdCounterRef = useRef(1);
  const handleSuccessResponse = ApiRes => {
    setEstesApiLoading(false);
    ApiRes.data.shipmentDetails.forEach(shipmentDetail => {
      const uniqueId = uniqueIdCounterRef.current;
      uniqueIdCounterRef.current = uniqueIdCounterRef.current + 1;

      const formattedDate = new Date(shipmentDetail.deliveryTime)
        .toLocaleString('en-US', options)
        .toUpperCase();
      const yearLastTwoDigits = formattedDate.slice(-2);

      // console.log('cccccccccccccccccccc', shipmentDetail);

      setSuccessRates(prevArray => [
        ...prevArray,
        {
          ...shipmentDetail,
          uniqueId: uniqueId,
          udeliveryTime: shipmentDetail.deliveryTime
            ? formattedDate.replace(yearLastTwoDigits, `${yearLastTwoDigits}`)
            : '',
          filterMode: !shipmentDetail.isGuaranteedDelviery
            ? 'standard'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('5')
            ? 'g5'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('10')
            ? 'g10'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('12')
            ? 'g12'
            : 'other'
        }
      ]);
    });
  };
  let tempErr = [];

  const handleRejectRepsone = err => {
    const ErrObj = {
      message: err?.response?.data,
      URL: err?.response?.request?.responseURL
      // name:
    };
    tempErr.push(ErrObj);
    if (tempErr.length === finalCarrierApis.length) {
      setChecked(true);
    }

    setErrorMessageApi(prevArray => [...prevArray, ErrObj]);
    setEstesApiLoading(false);
  };

  const fetchData = async (RequoteObj, DeepCloneRequoteObj) => {
    const GetAuthToken = () => {
      return localStorage.getItem('UserAuthToken');
    };

    const AgencyLoginHeader = axios.create({
      baseURL: base_url,
      responseType: 'json'
    });

    // Add an interceptor to update the Authorization header before each request
    AgencyLoginHeader.interceptors.request.use(
      async config => {
        const authToken = GetAuthToken();
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    setData([]);
    setSuccessRates([]);
    setGetRatesArray([]);
    setErrorMessageApi([]);
    if (RequoteObj !== undefined) {
      setOriginLoc(
        RequoteObj.shipFrom.city +
          ' ' +
          RequoteObj.shipFrom.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipFrom.postalCode
      );
      setDestinationLoc(
        RequoteObj.shipTo.city +
          ' ' +
          RequoteObj.shipTo.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipTo.postalCode
      );
      setRequoteWeight(RequoteObj?.commodityInfo[0]?.weight?.value);
    }
    finalCarrierApis.length > 0
      ? setEstesApiLoading(true)
      : setEstesApiLoading(false);

    let temp = RequoteObj !== undefined ? [] : [...data];
    for (let i = 0; i < finalCarrierApis.length; i++) {
      // setNoAssignedCarriers(false);
      const EndPoint = finalCarrierApis[i];
      RequoteObj !== undefined
        ? AgencyLoginHeader.post(EndPoint, RequoteObj)
            .then(res => {
              res.status === 200 && handleSuccessResponse(res);
            })
            .catch(err => handleRejectRepsone(err))
        : AgencyLoginHeader.post(EndPoint, obj)
            .then(res => {
              res.status === 200 && handleSuccessResponse(res);
            })
            .catch(err => handleRejectRepsone(err));
    }
  };

  const handleGetCarrierNames = data => {
    let tempApis = [];
    let CarrierArray = [];
    if (shipTypeValue == 'LTL') {
      CarrierArray = data.filter(
        items => items.active && items.cType === 'LTL'
      );
    } else {
      CarrierArray = data.filter(
        items => items.active && items.cType === 'Parcel'
      );
    }
    if (CarrierArray.length === 0) {
      setNoAssignedCarriers(true);
    } else {
      setNoAssignedCarriers(false);
    }
    for (let i = 0; i < CarrierArray.length; i++) {
      const CarrierName = CarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      tempApis.push(element);
      // tempApis.push(element.includes('YRC') ? element : null);
    }
    setFinalCarrierApis(tempApis);
    // getPrice();
  };
  const handleApi = EndPoint => {};
  let temp = [];
  let Err = [];

  useEffect(() => {
    setEstesApiLoading(true);
    fetchData();
  }, [finalCarrierApis]);

  useEffect(() => {
    GetActiveCarriers(AgencyID, locationId)
      .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
      .catch(err => console.log('Error here...', err));
  }, []);

  const [checked, setChecked] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        // margin: '10 0 0 0',
        padding: '10 0 10 0',

        backgroundColor: '#EDF2F900'
      }}
    >
      <div
        style={{
          backgroundColor: '#EDF2F900'
        }}
      >
        <Paper style={{ borderRadius: 20, maxHeight: '70vh' }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div>
                  <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                    <QuickQuoteTabPanel
                      OriginLocation={originLoc}
                      DestinationLocation={destinationLoc}
                      OrderWeight={requoteWeight}
                      selectedClass={selectedClass}
                      errorMessageApi={errorMessageApi}
                      quote={quote}
                      fetchData={fetchData}
                      obj={obj}
                      SelectedRowData={SelectedRowData}
                      successRates={successRates}
                      EstesApiLoading={EstesApiLoading}
                      checkedProp={checked}
                      shipTypeValue={shipTypeValue}
                      noAssignedCarriers={noAssignedCarriers}
                    />
                  </Box>
                </div>
              </div>
            </CardContent>
          </Card>
        </Paper>
      </div>
    </div>
  );
}

export default React.memo(QuickQuoteRates);

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './navbar.css';
import { AagencyLogout } from '../../apis/Agency';
import { setRateValues } from '../../Redux/GetRatesSlice';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserDetailsObj,
  setUserRolePermissionsArr,
  setUserToken
} from '../../Redux/UserSlice';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import {
  setClassWeight,
  setDimensionsWeight
} from '../../Redux/QuickQuoteTotalWeightSlice';
import './additionalSideBar.css';
import { Typography } from '@mui/material';

const NavBar = props => {
  const UserDetails = useSelector(state => state.user.UserDetailsObj);
  const AgencyAdminDetails = useSelector(
    state => state.user.AgencyAdminDetails
  );

  const [shipmentColor, setShipmentColor] = useState(false);
  const [quickColor, setQuickColor] = useState(false);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile(prevState => !prevState);

  //scroll navbar
  const [navigate, setNavigate] = useState('');

  setTimeout(() => {
    const Home = localStorage.getItem('Home');
    setNavigate(Home);
  }, 3000);

  const dispatch = useDispatch();
  const rememberMe = useSelector(state => state.userDetails.rememberMe);

  const handleLogout = () => {
    AagencyLogout()
      .then(res => console.log(res, 'AagencyLogout'))
      .catch(err => console.log(err, 'AagencyLogoutErr'));
    localStorage.clear();
    !rememberMe && dispatch(setPasswordRedux(''));
    !rememberMe && dispatch(setEmailRedux(''));
    !rememberMe && dispatch(setUserDetailsObj({}));
    localStorage.clear();
    document.cookie =
      'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    dispatch(setUserToken(''));
    dispatch(setLoginAgencyID(''));
    dispatch(setUserRolePermissionsArr([]));
    dispatch(setUserToken(''));
    dispatch(setLoginAgencyID(''));
    dispatch(setRateValues(''));
    dispatch(setQuickQuoteRateValues(''));
    dispatch(setQuickQuotePickUpCheckedRedux(false));
    dispatch(setQuickQuoteDeliveryCheckedRedux(false));
    dispatch(setClassWeight(''));
    dispatch(setDimensionsWeight(''));
    dispatch(setUserRolePermissionsArr([]));
  };

  const currentScreenText = {
    '/': 'Shipments',
    '/dashboard': 'Dashboard',
    '/manage-shipments': 'Shipments',
    '/carriers': 'Carriers',
    '/view-shipment': 'View Shipment',
    '/users': 'Users',
    '/view-locations': 'Locations',
    '/settings': 'Settings',
    '/app-registration': 'App Registration',
    '/quick-quote': 'Quick Quote',
    '/get-rates': 'Quick Quote',
    '/bookshipmentLTL': 'Book Shipment',
    '/create-carrier': 'Create Carrier'
  };
  const currentScreen = useLocation();
  const firstLetter = UserDetails.firstName
    ? UserDetails.firstName.charAt(0).toUpperCase()
    : '';

  const lastLetter = UserDetails.lastName
    ? UserDetails.lastName.charAt(0).toUpperCase()
    : '';

  const imageStyle = {
    objectFit: 'cover',
    background: 'white',
    borderRadius: '8px',
    height: '45px',
    width: '45px',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#133'
  };
  return (
    <React.Fragment>
      <div class='top-nav-bar'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <Typography variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {currentScreenText[currentScreen.pathname]}
          </Typography>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            {/* <FilledInput
              placeholder='Search'
              style={{
                paddingBottom: 18,
                background: '#FFFFFF',
                // padding: '7px',
                borderRadius: '8px',
                height: '45px'
              }}
              endAdornment={
                <InputAdornment sx={{ paddingTop: 2.5 }}>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.0127 14.9167L9.12144 10.25C8.68471 10.5833 8.18249 10.8472 7.61475 11.0417C7.04701 11.2361 6.44288 11.3333 5.80236 11.3333C4.21561 11.3333 2.8727 10.809 1.77362 9.76042C0.67454 8.71181 0.125 7.43056 0.125 5.91667C0.125 4.40278 0.67454 3.12153 1.77362 2.07292C2.8727 1.02431 4.21561 0.5 5.80236 0.5C7.38911 0.5 8.73203 1.02431 9.83111 2.07292C10.9302 3.12153 11.4797 4.40278 11.4797 5.91667C11.4797 6.52778 11.3778 7.10417 11.174 7.64583C10.9702 8.1875 10.6936 8.66667 10.3443 9.08333L15.2574 13.7708C15.4175 13.9236 15.4975 14.1111 15.4975 14.3333C15.4975 14.5556 15.4102 14.75 15.2355 14.9167C15.0754 15.0694 14.8716 15.1458 14.6241 15.1458C14.3766 15.1458 14.1728 15.0694 14.0127 14.9167ZM5.80236 9.66667C6.89416 9.66667 7.82219 9.30208 8.58645 8.57292C9.35071 7.84375 9.73284 6.95833 9.73284 5.91667C9.73284 4.875 9.35071 3.98958 8.58645 3.26042C7.82219 2.53125 6.89416 2.16667 5.80236 2.16667C4.71056 2.16667 3.78253 2.53125 3.01827 3.26042C2.25401 3.98958 1.87188 4.875 1.87188 5.91667C1.87188 6.95833 2.25401 7.84375 3.01827 8.57292C3.78253 9.30208 4.71056 9.66667 5.80236 9.66667Z'
                      fill='#2D3C59'
                    />
                  </svg>
                </InputAdornment>
              }
              disableUnderline={true}
            /> */}

            {/* <Dropdown
              isOpen={notification}
              toggle={dropDownnotification}
              className='list-inline-item  '
            >
              <DropdownToggle
                href='#'
                className='Grid-Noti-Icon header-item noti-icon position-relative'
                id='notification'
                type='button'
                tag='a'
              >
                <div
                  style={{
                    marginLeft: 10,
                    cursor: 'pointer',
                    background: 'white',
                    borderRadius: '8px',
                    height: '45px',
                    width: '45px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <svg
                    width='14'
                    height='18'
                    viewBox='0 0 14 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.335938 14.8307V13.1641H2.0026V7.33073C2.0026 6.17795 2.34983 5.15365 3.04427 4.25781C3.73872 3.36198 4.64149 2.77517 5.7526 2.4974V1.91406C5.7526 1.56684 5.87413 1.2717 6.11719 1.02865C6.36024 0.78559 6.65538 0.664062 7.0026 0.664062C7.34983 0.664062 7.64497 0.78559 7.88802 1.02865C8.13108 1.2717 8.2526 1.56684 8.2526 1.91406V2.4974C9.36372 2.77517 10.2665 3.36198 10.9609 4.25781C11.6554 5.15365 12.0026 6.17795 12.0026 7.33073V13.1641H13.6693V14.8307H0.335938ZM7.0026 17.3307C6.54427 17.3307 6.15191 17.1675 5.82552 16.8411C5.49913 16.5148 5.33594 16.1224 5.33594 15.6641H8.66927C8.66927 16.1224 8.50608 16.5148 8.17969 16.8411C7.8533 17.1675 7.46094 17.3307 7.0026 17.3307ZM3.66927 13.1641H10.3359V7.33073C10.3359 6.41406 10.0095 5.62934 9.35677 4.97656C8.70399 4.32378 7.91927 3.9974 7.0026 3.9974C6.08594 3.9974 5.30122 4.32378 4.64844 4.97656C3.99566 5.62934 3.66927 6.41406 3.66927 7.33073V13.1641Z'
                      fill='#2D3C59'
                    />
                  </svg>
                </div>
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-sm dropdown-menu-end p-0'
                aria-labelledby='notification'
                end
              >
                <div className='notification-header border-bottom bg-light'>
                  <h6 className='mb-1'> Notifications </h6>
                </div>
                <div className='notification-wrapper dropdown-scroll'></div>
                <div className='notification-footer border-top text-center'>
                  <Link className='primary-link fs-13' to='#'>
                    <i className='mdi mdi-arrow-right-circle me-1'></i>{' '}
                    <span>View More..</span>
                  </Link>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <Dropdown
              onClick={() => setUserProfile(!userProfile)}
              isOpen={userProfile}
              toggle={dropDownuserprofile}
              className='list-inline-item'
            >
              <DropdownToggle
                to='#'
                // className='header-item'
                id='userdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
                // style={{ height: 29 }}
              >
                {AgencyAdminDetails?.userAvatar ? (
                  <img
                    src={`https://tms.dynamicwerx.com/${AgencyAdminDetails?.userAvatar}`}
                    alt='adminBase64Image'
                    width='200'
                    style={imageStyle}
                  />
                ) : (
                  <img
                    src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                      firstLetter + lastLetter
                    )}</text></svg>`}
                    // src={imageUrl}
                    alt={UserDetails.firstName}
                    style={imageStyle}
                  />
                )}
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-end'
                aria-labelledby='userdropdown'
                end
              >
                <li>
                  <Link
                    className='dropdown-item'
                    to='/user-profile'
                    onClick={() => {
                      setShipmentColor(false);
                      setQuickColor(false);
                    }}
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className='dropdown-item'
                    onClick={handleLogout}
                    to='/agency-signin'
                  >
                    Logout
                  </Link>
                </li>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NavBar);

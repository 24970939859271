import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import UserProfile from './UserProfile';
import './MyProfile.css';
import { base_url } from '../../../apis/constants';

const MyProfile = () => {
  const [user, setUser] = useState();
  const [education, setEducation] = useState();
  const [experience, setExperience] = useState();

  // useEffect(async () => {
  //   const Id = localStorage.getItem('UserId');
  //   getCurrentUserData(Id).then(resp => {
  //     setUser(resp.data);
  //   });
  //   getEducation(setEducation, currentuserid);
  //   getExperience(setExperience, currentuserid);
  // }, []);

  return (
    <div>
      <MetaTags>
        {/* <title>User Profile | TMS</title> */}
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'User Profile | Shiptechpro'
            : 'User Profile | TMS'}
        </title>
      </MetaTags>
      {/* <section className='section'> */}
      <Container style={{ marginTop: -30 }}>
        <UserProfile user={user} />
      </Container>
      {/* </section> */}
    </div>
  );
};

export default MyProfile;
